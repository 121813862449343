import { FC, useEffect } from 'react';

import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import GlobalStyles from './theme/GlobalStyles';

import { useDispatch } from 'react-redux';
import { Dispatch } from './store';

import Router from 'router/index';

import theme from './theme';

import 'react-toastify/dist/ReactToastify.css';
import { CONSTANTS, firebaseVapidKey } from 'app.config';
import { getLanguage } from 'utils';
import { messaging } from 'firebaseInit';
import { getToken, onMessage } from 'firebase/messaging';
import { updateDeviceToken } from 'http/authService';
import AgoraProvider from 'context/AgoraContext';
import EditorProvider from 'context/EditorContext';
import { SocketProvider } from 'context/SocketContex';

const App: FC = () => {
  const dispatch = useDispatch<Dispatch>();

  const token = localStorage.getItem(CONSTANTS.TOKEN);

  useEffect(() => {
    if (token) {
      dispatch.auth.handleGetCurrentUser();
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          console.log('Notification permission granted.');

          // Get registration token
          getToken(messaging, { vapidKey: firebaseVapidKey })
            .then(currentToken => {
              if (currentToken) {
                updateDeviceToken(currentToken).then(res =>
                  console.log(res.data)
                );
                // Send the token to your server to subscribe to a topic
              } else {
                console.log('No registration token available.');
              }
            })
            .catch(err => {
              console.log('An error occurred while retrieving token. ', err);
            });
        } else {
          console.log('Notification permission denied.');
        }
      });
    }

    console.log(getLanguage());
    // eslint-disable-next-line
  }, [token]);

  useEffect(() => {
    onMessage(messaging, payload => {
      dispatch.notifications.handleGetNotifications();
    });
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <SocketProvider>
        <AgoraProvider>
          <EditorProvider>
            <GlobalStyles />
            <Router />
            <ToastContainer />
          </EditorProvider>
        </AgoraProvider>
      </SocketProvider>
      {/* <ResponsiveHelper /> */}
    </ThemeProvider>
  );
};

export default App;
