import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { RootState, Dispatch } from 'store';

import Accordion from './Accordion';
import ASSETS from 'assets';
import { t } from 'i18next';
import LocationAutoComplete from 'components/LocationAutoComplete/LocationAutoComplete';

const Location = () => {
  const dispatch = useDispatch<Dispatch>();

  const { location } = useSelector((state: RootState) => state.filters);

  const [options, setOptions] = useState<string[]>([]);

  const handleOnUpdate = (val: string) => {
    dispatch.filters.setLocationFilters(val);
  };

  const handleSelect = (op: string) => {
    dispatch.filters.setLocationFilters(op);
  };

  return (
    <Accordion label='Location' reOpenWhen={options}>
      <div className='px-2 pb-2 flex flex-col gap-1'>
        <div className='flex items-center gap-1 flex-wrap'>
          {location.map(s => (
            <div className='bg-secondary px-[7px] min-h-7 py-1 flex items-center rounded'>
              <p className='text-[10px] font-medium'>{t(s)}</p>
              <img
                src={ASSETS.cross}
                className='w-3 h-3 cursor-pointer'
                onClick={() => handleSelect(s)}
                alt=''
              />
            </div>
          ))}
        </div>

        <LocationAutoComplete
          onOptionChange={setOptions}
          selected={location}
          onUpdate={handleOnUpdate}
          placeholder='Search'
          containerStyle='min-h-[34px] px-2'
          inputStyles='!text-sm placeholder:opacity-50'
          menuStyles='px-1 text-xs min-h-[30px]'
        />
        {options.length > 0 && (
          <div className='mt-8 w-full'>
            {options.map(m => (
              <p className='px-1 text-xs min-h-[30px] text-transparent'>{m}</p>
            ))}
          </div>
        )}

        {/* <div className='border border-grey-400 h-9 rounded-md bg-white overflow-hidden relative'>
          <input
            type='search'
            name=''
            placeholder={t('Search City or State')}
            className='w-full h-full text-sm outline-none border-none pl-5 pr-3'
            id=''
            value={search}
            onChange={handleChange}
          />
          <div className='absolute left-0 top-0 h-full w-[20px] aspect-square grid place-items-center'> */}
        {/* <img src={ASSETS.search} className='w-4' alt='' /> */}
        {/* </div>
        </div>
        <div className='flex flex-col rounded bg-white max-h-[300px] empty:hidden overflow-auto'>
          {options.map(op => {
            if (op.title.toLowerCase().includes(search.toLowerCase())) {
              return (
                <div
                  key={op.title}
                  className='flex items-center py-2 gap-2 px-2 border-b border-b-grey-400
                  last-of-type:border-b-0
                  '
                  onClick={() => handleSelect(op)}
                >
                  <img
                    src={
                      location.includes(op)
                        ? ASSETS.checkboxCheck
                        : ASSETS.checkbox
                    }
                    className='w-5'
                    alt=''
                  />
                  <p className='text-sm'>{t(op.title)}</p>
                </div>
              );
            } else {
              return null;
            }
          })}
        </div> */}
      </div>
    </Accordion>
  );
};

export default Location;
