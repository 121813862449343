import { FC, useEffect, useRef, useState } from 'react';
import ASSETS from 'assets';
import classNames from 'classnames';

interface IProps {
  options: Array<string>;
  placeholder: string;
  renderIcons?: boolean;
  lable?: string;
  searchIcon?: boolean;
  position?: 'static' | 'absolute';
  keepOpen?: boolean;
  onSelect?: (val: string) => void;
  selected?: string[] | string;
  onDeselect?: (val: string) => void;
  inputClassName?: string;
  error?: string;
  showDownArrow?: boolean;
  showMenu?: boolean;
  disableSearch?: boolean;
}

const AutocompleteSelect: FC<IProps> = ({
  options,
  placeholder,
  renderIcons = true,
  lable,
  searchIcon = true,
  position = 'absolute',
  keepOpen = false,
  onSelect,
  selected = [],
  onDeselect,
  inputClassName,
  error,
  showDownArrow = true,
  showMenu = false,
  disableSearch = false,
}) => {
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const toggleMenu = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    if (!keepOpen) {
      setOpen(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value);
  };

  const handleSelect = (val: string) => {
    if (typeof onSelect === 'function') {
      onSelect(val);
      setSearch('');
      handleClose(); // Close the menu after selecting an option
    }
  };

  const handleDeselect = (val: string) => {
    if (typeof onDeselect === 'function') {
      onDeselect(val);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        handleClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className='w-full max-w-[800px]' ref={containerRef}>
      {lable && <p className='text-base font-medium mb-1'>{lable}</p>}
      <div className='relative'>
        <div
          className={classNames(
            'flex items-center gap-2 bg-white border border-grey-400 rounded min-h-11 px-[15px] flex-wrap py-1 relative',
            inputClassName,
            error && 'border-red'
          )}
          onClick={toggleMenu}
        >
          {searchIcon && (
            <img src={ASSETS.search} className='w-[18px] h-[18px]' alt='' />
          )}
          {typeof selected === 'string' ? (
            <p className='text-sm font-medium'>{selected}</p>
          ) : (
            <>
              {selected.map(i => (
                <div
                  key={i}
                  className='bg-secondary px-[7px] h-7 flex items-center rounded'
                >
                  <p className='text-[12px] font-medium'>{i}</p>
                  <img
                    src={ASSETS.cross}
                    className='w-3 h-3 cursor-pointer'
                    onClick={() => handleDeselect(i)}
                    alt=''
                  />
                </div>
              ))}
            </>
          )}

          <input
            value={search}
            onChange={handleChange}
            type='text'
            className='flex-grow h-full outline-none text-base font-medium placeholder:opacity-85 placeholder:text-black'
            placeholder={selected.length === 0 ? placeholder : ''}
            readOnly={disableSearch}
          />
          {showDownArrow && (
            <div className='absolute right-0 top-0 h-full aspect-square grid place-items-center'>
              <img src={ASSETS.chevronDown} alt='' />
            </div>
          )}
        </div>
        {(search || showMenu) && (open || keepOpen) && (
          <div
            className={classNames(
              'border-[0.5px] flex-grow border-grey-400 bg-white rounded mt-[5px] shadow-mm max-h-[313px] overflow-auto empty:hidden z-20',
              position === 'absolute' &&
                'absolute bottom-0 w-full translate-y-[calc(100%_+_8px)] z-20'
            )}
          >
            {options.map(op => {
              if (op.toLowerCase().includes(search.toLowerCase())) {
                return (
                  <div
                    key={op}
                    className='flex cursor-pointer items-center gap-[13px] py-[10px] px-4 border-b-[0.5px] border-b-grey-400 last-of-type:border-b-0'
                    onClick={() => {
                      handleSelect(op);
                      handleClose();
                    }}
                  >
                    {renderIcons && (
                      <img
                        src={
                          selected.includes(op)
                            ? ASSETS.checkboxCheck
                            : ASSETS.checkbox
                        }
                        alt=''
                      />
                    )}
                    <p className='text-sm font-medium'>{op}</p>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        )}
      </div>
      {error && (
        <div className='flex items-center gap-1 mt-1'>
          <img src={ASSETS.errorInfo} alt='' />
          <p className='text-xs text-error'>{error}</p>
        </div>
      )}
    </div>
  );
};

export default AutocompleteSelect;
