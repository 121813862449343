import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';

import TextField from 'components/TextField/TextField';
import Button from 'components/Button/Button';
import QuilEditor from 'components/QuillEditor/QuilEditor';
import LocationPicker from 'components/LocationPicker/LocationPicker';
import { t } from 'i18next';
import { stateAbbreviations } from 'app.config';

const schema = object({
  name: string().required('Required field').label('Company Name'),
  description: string()
    .required('Required field')
    .max(2500)
    .label('Company Description'),
  location: string().required('Required field').label('Location'),
});

const CreateCompany = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const { selected, loading } = useSelector(
    (state: RootState) => state.companies
  );

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      location: '',
    },
    onSubmit: values => {
      if (selected) {
        return navigate('/create-job');
      }

      dispatch.companies.HandleCreateCompany({
        values: { ...values },
        navigate,
      });
    },
    validationSchema: schema,
  });

  const onLocationChange = (val: string) => {
    formik.setFieldValue('location', val);
  };

  useEffect(() => {
    if (selected) {
      formik.setValues({
        name: selected.name,
        description: selected.description,
        location: selected.location,
      });
    }
    // eslint-disable-next-line
  }, [selected]);

  return (
    <div className='flex-grow'>
      <div className='w-full 2xl:pt-[70px] pt-[20px] max-w-[776px] bg-white border-[0.5px] border-grey-600 2xl:px-11 px-7 rounded-[12px] mx-auto'>
        <TextField
          containerClassName='2xl:!h-[70px]'
          inputClassName='2xl:!text-lg'
          placeholder={`${t('Company Name')}*`}
          readOnly={!!selected}
          name='name'
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.name && formik.errors.name ? formik.errors.name : ''
          }
        />
        <div className='2xl:mt-[23px] my-[14px]'>
          <QuilEditor
            limit={2500}
            value={formik.values.description}
            onChange={val => formik.setFieldValue('description', val)}
            error={
              formik.touched.description && formik.errors.description
                ? formik.errors.description
                : ''
            }
          />
          {/* <Textarea
            label=''
            rows={8}
            placeholder='Company Description*'
            name='company_desc'
            value={formik.values.company_desc}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.company_desc && formik.errors.company_desc
                ? formik.errors.company_desc
                : ''
            }
          />
          <p className='text-base text-placeholder text-right'>
            {formik.values.company_desc.length}/200
          </p> */}
        </div>
        {/* <TextField
          containerClassName='2xl:!h-[70px]'
          inputClassName='2xl:!text-lg'
          placeholder='Location*'
          icon={ASSETS.location}
          name='location'
          value={formik.values.location}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.location && formik.errors.location
              ? formik.errors.location
              : ''
          }
        /> */}
        <LocationPicker
          containerClassName='2xl:!h-[70px]'
          inputClassName='2xl:!text-lg'
          name='location'
          placeholder={t('Location*')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.location}
          error={
            formik.touched.location && formik.errors.location
              ? formik.errors.location
              : ''
          }
          onValueChange={onLocationChange}
        />
        <Button
          label={t('Next')}
          className='!w-full 2xl:!text-22 !text-lg 2xl:my-[66px] my-6'
          type='submit'
          onClick={() => formik.handleSubmit()}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default CreateCompany;
