import { FC } from 'react';

import styled from 'styled-components';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import ForgotPassword from 'Dialogs/ForgotPassword/ForgotPassword';
import ForgotEmail from 'Dialogs/ForgotEmail/ForgotEmail';
import EmailVerification from 'Dialogs/EmailVerification/EmailVerification';
import EmailVerified from 'Dialogs/EmailVerified/EmailVerified';
import ASSETS from 'assets';

const AuthLayout: FC = () => {
  const { emailVerification, forgotEmail, forgotPassword, emailVerified } =
    useSelector((state: RootState) => state.utils);
  return (
    <Container>
      <header className='2xl:h-22 h-16 px-12 flex items-center justify-between sticky'>
        <img src={ASSETS.logo_fr} className='w-[50px]' alt='' />
        <button className='bg-white text-base font-medium h-12 w-[195px] border-2 rounded-full  border-black'>
          Get the App
        </button>
      </header>
      <div className='content 2xl:pt-[92px] pt-[64px]'>
        <main>
          <Outlet />
        </main>
      </div>
      {forgotEmail === true && <ForgotEmail />}
      {forgotPassword === true && <ForgotPassword />}
      {emailVerification.open === true && <EmailVerification />}
      {emailVerified.open === true && <EmailVerified />}
    </Container>
  );
};

export default AuthLayout;

const Container = styled.div`
  min-height: 100dvh;

  & header {
    background: #00000030;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100dvw;
  }

  & .content {
    height: 100dvh;

    :has(.no-pad) {
      padding-top: 0px;
    }

    & main {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
