import React, { useEffect, useState } from 'react';
import ImagePicker from 'components/ImagePicker/ImagePicker';
import { toast } from 'react-toastify';
import { stateAbbreviations } from 'app.config';
import { number, object, string } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'store';
import { useFormik } from 'formik';

import { t } from 'i18next';
import TextField from 'components/TextField/TextField';
import LocationPicker from 'components/LocationPicker/LocationPicker';
import Button from 'components/Button/Button';
import DatePicker from 'components/DatePicker/DatePicker';
import dayjs from 'dayjs';
import { IUpdateApplicantDetails } from 'types/api';
import Checkbox from 'components/Checkbox/Checkbox';
import classNames from 'classnames';
import JobTitleSelector from 'components/JobTitleSelector/JobTitleSelector';

const schema = object({
  first_name: string().required('Required field').label('First Name'),
  last_name: string().required('Required field').label('Last Name'),
  job_title: string().required('Required field').label('Job Title'),
  location: string().required('Required field').label('Location'),
  company_name: string().optional().label('Current Employer'),
  start_date: string().optional().label('Start Date'),
  end_date: string().optional().label('End Date'),
  years_of_exp: number()
    .positive()
    .typeError('Years of Experience must be a proper year')
    .optional()
    .label('Years of Experience'),
  degree: string().optional().label('Degree'),
  school: string().optional().label('School'),
  graduation_year: number()
    .positive('Graduation Year must be a number')
    .test(
      'len',
      'Graduation Year must be exactly 4 digits',
      val => !val || val?.toString().length === 4
    )
    .optional()
    .label('Graduation Year'),
});

const EditProfile = () => {
  const dispatch = useDispatch<Dispatch>();
  const { user } = useSelector((state: RootState) => state.auth);
  const { loading } = useSelector((state: RootState) => state.user);
  const [sLoading, setSLoading] = useState(false);

  const [checked, setChecked] = useState(false);

  const [image, setImage] = useState<File | null | string>(null);
  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      job_title: '',
      location: '',
      company_name: '',
      start_date: '',
      end_date: '',
      years_of_exp: '',
      degree: '',
      school: '',
      graduation_year: '',
    },
    validationSchema: schema,
    onSubmit: values => {
      setSLoading(true);
      const { first_name, last_name, job_title, location, ...education } =
        values;
      if (image && typeof image !== 'string') {
        const formData = new FormData();
        formData.append('profile', image);

        dispatch.user.handleUpdateProfile({
          values: {
            ...{ first_name, last_name, job_title, location },
          },
          afterUpdate: () => {
            toast.success('Updated Successfully!');
            setSLoading(false);
          },
          profile: formData,
        });
      } else {
        dispatch.user.handleUpdateProfile({
          values: {
            ...{ first_name, last_name, job_title, location },
          },
          afterUpdate: () => {
            toast.success('Updated Successfully!');
            setSLoading(false);
          },
        });
      }

      const val: { [x: string]: any } = {};

      Object.keys(education).forEach(key => {
        // @ts-ignore
        if (values[key]) {
          // @ts-ignore
          val[key] = values[key];
        } else {
          val[key] = null;
        }
      });
      const data = {
        ...val,
        start_date: values.start_date
          ? dayjs(values.start_date).format('MM-DD-YYYY')
          : null,
        end_date: values.end_date
          ? dayjs(values.end_date).format('MM-DD-YYYY')
          : null,
      };

      if (checked) {
        data.end_date = 'present';
      }

      dispatch.user.handleUpdateApplicantProfile({
        values: data as IUpdateApplicantDetails,
      });
    },
  });

  const onLocationChange = (val: string) => {
    formik.setFieldValue('location', val);
  };

  useEffect(() => {
    if (user) {
      formik.setValues({
        first_name: user.first_name,
        last_name: user.last_name,
        job_title: user.job_title || '',
        location: user.location || '',
        company_name: user?.userDetails?.company_name || '',
        start_date: user?.userDetails?.start_date || '',
        end_date:
          user?.userDetails?.end_date === 'present'
            ? ''
            : user?.userDetails?.end_date || '',
        years_of_exp: user?.userDetails?.years_of_exp || '',
        degree: user?.userDetails?.degree || '',
        school: user?.userDetails?.school || '',
        graduation_year: user?.userDetails?.graduation_year || '',
      });
      setImage(user.image_url);
      if (user.userDetails?.end_date === 'present') {
        setChecked(true);
      }
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <div className='w-full '>
      <div className='w-full max-w-[776px] bg-white border-[0.5px] border-grey-400 rounded-[12px] 2xl:px-11 px-5 2xl:pt-6 2xl:pb-12 pt-5 pb-6 mx-auto'>
        <p className='2xl:text-3xl text-2xl font-semibold text-primary'>
          {t('Profile')}
        </p>
        <div className=' w-[140px] mx-auto pb-3'>
          <ImagePicker image={image} setImage={setImage} />
        </div>
        <form
          onSubmit={e => e.preventDefault()}
          className='flex flex-col 2xl:gap-6 gap-4 w-full mt-5'
        >
          <div className='grid grid-cols-2 gap-4'>
            <TextField
              label=''
              placeholder={t('Name*')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.first_name}
              name='first_name'
              error={
                formik.touched.first_name && formik.errors.first_name
                  ? formik.errors.first_name
                  : ''
              }
            />
            <TextField
              label=''
              placeholder={t('Name*')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.last_name}
              name='last_name'
              error={
                formik.touched.last_name && formik.errors.last_name
                  ? formik.errors.last_name
                  : ''
              }
            />
            <JobTitleSelector
              value={formik.values.job_title}
              onChange={(val: string) => formik.setFieldValue('job_title', val)}
              error={
                formik.touched.job_title && formik.errors.job_title
                  ? (formik.errors.job_title as string)
                  : ''
              }
            />
            {/* <TextField
              label=''
              placeholder={t('Job Title*')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.job_title}
              name='job_title'
              error={
                formik.touched.job_title && formik.errors.job_title
                  ? formik.errors.job_title
                  : ''
              }
            /> */}

            <LocationPicker
              name='location'
              placeholder={t('Location*')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.location}
              error={
                formik.touched.location && formik.errors.location
                  ? formik.errors.location
                  : ''
              }
              onValueChange={onLocationChange}
            />
            <div className='col-span-2 py-2'>
              <p className='text-2xl text-primary font-medium'>
                {t('Education & Employment')}
              </p>
            </div>
            <div className='col-span-2'>
              <TextField
                //   containerClassName='2xl:!h-[70px] !h-[48px]'
                //   inputClassName='2xl:!text-lg'
                placeholder={t('Current Employer')}
                name='company_name'
                value={formik.values.company_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.company_name && formik.errors.company_name
                    ? formik.errors.company_name
                    : ''
                }
              />
            </div>
            <DatePicker
              noLabel
              placeholder={t('Start Date')}
              value={formik.values.start_date}
              onChange={val => formik.setFieldValue('start_date', val)}
              error={
                formik.touched.start_date && formik.errors.start_date
                  ? formik.errors.start_date
                  : ''
              }
              containerClassName='!h-[48px]'
            />
            <div>
              <DatePicker
                noLabel
                placeholder={t('End Date')}
                value={formik.values.end_date}
                onChange={val => formik.setFieldValue('end_date', val)}
                error={
                  formik.touched.end_date && formik.errors.end_date
                    ? formik.errors.end_date
                    : ''
                }
                containerClassName={classNames(
                  '!h-[48px]',
                  checked && 'opacity-50 pointer-events-none'
                )}
              />
              <div className='flex items-center  mt-4 gap-2'>
                <Checkbox onChange={v => setChecked(v)} value={checked} />
                <p className='text-placeholder text-sm'>Currently Working</p>
              </div>
            </div>
            <TextField
              //   containerClassName='2xl:!h-[70px] !h-[48px]'
              //   inputClassName='2xl:!text-lg'
              placeholder={t('Years of Experience')}
              name='years_of_exp'
              value={formik.values.years_of_exp}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.years_of_exp && formik.errors.years_of_exp
                  ? formik.errors.years_of_exp
                  : ''
              }
            />
            <TextField
              // containerClassName='2xl:!h-[70px] !h-[48px]'
              // inputClassName='2xl:!text-lg'
              placeholder={t('Degree')}
              name='degree'
              value={formik.values.degree}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.degree && formik.errors.degree
                  ? formik.errors.degree
                  : ''
              }
            />
            <TextField
              // containerClassName='2xl:!h-[70px] !h-[48px]'
              // inputClassName='2xl:!text-lg'
              placeholder={t('School')}
              name='school'
              value={formik.values.school}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.school && formik.errors.school
                  ? formik.errors.school
                  : ''
              }
            />
            <TextField
              // containerClassName='2xl:!h-[70px] !h-[48px]'
              // inputClassName='2xl:!text-lg'
              placeholder={t('Graduation Year')}
              name='graduation_year'
              value={formik.values.graduation_year}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.graduation_year && formik.errors.graduation_year
                  ? formik.errors.graduation_year
                  : ''
              }
            />
          </div>
          <Button
            label={t('Save Changes')}
            className='!w-full 2xl:!h-[56px] 2xl:!text-22 !text-lg 2xl:mt-[20px] mt-4'
            onClick={() => formik.handleSubmit()}
            loading={loading || sLoading}
          />
        </form>
      </div>
    </div>
  );
};

export default EditProfile;
