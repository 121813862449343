import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { RootState } from 'store';

import Accordion from './Accordion';
import ASSETS from 'assets';
import { IHelpCategory, IHelpQuestion } from 'types/interfaces';
import { getAllHelpSubCategories, getAllQuestions } from 'http/settingsService';
import { ClipLoader } from 'react-spinners';
import theme from 'theme';

const HelpCenter = () => {
  const [selectedCategory, setSelectedCategory] =
    useState<null | IHelpCategory>(null);

  const [selectedSubCategory, setSelectedSubCategory] =
    useState<null | IHelpCategory>(null);

  const [subCategories, setSubCategories] = useState<IHelpCategory[]>([]);
  const [questions, setQuestions] = useState<IHelpQuestion[]>([]);

  const [cLoading, setCLoading] = useState(false);

  const [qLoading, setQloading] = useState(false);

  const { helpCenter } = useSelector((state: RootState) => state.faqs);

  const handleSelectCategory = async (c: IHelpCategory) => {
    try {
      setCLoading(true);
      setSelectedCategory(c);
      const { data } = await getAllHelpSubCategories(c.id);
      setSubCategories(data.data);
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setCLoading(false);
    }
  };

  const handleSelectSubCategory = async (c: IHelpCategory) => {
    try {
      setQloading(true);
      setSelectedSubCategory(c);
      const { data } = await getAllQuestions(c.id);
      setQuestions(data.data);
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setQloading(false);
    }
  };

  return (
    <div className='2xl:px-6 px-4 2xl:py-9 py-5'>
      <p className='2xl:text-2xl text-xl font-bold'>
        Help Center{' '}
        {selectedCategory && (
          <span className='text-xs'>/ {selectedCategory.title}</span>
        )}
      </p>
      <div className='flex flex-col gap-[5px] mt-4'>
        {!selectedCategory && !selectedSubCategory && (
          <>
            {helpCenter.map(h => (
              <div
                key={h.id}
                className='flex items-center justify-between 2xl:h-12 h-10 border-b-[0.5px] border-grey-600 px-2 bg-grey-100 cursor-pointer'
                onClick={() => handleSelectCategory(h)}
              >
                <p className='2xl:text-lg text-base font-medium'>{h.title}</p>
                <img src={ASSETS.arrowRight} alt='' />
              </div>
            ))}
          </>
        )}
        {selectedCategory && (
          <>
            <div className='py-2'>
              <button
                className='flex items-center gap-1'
                onClick={() => {
                  setSelectedCategory(null);
                  setSelectedSubCategory(null);
                }}
              >
                <img src={ASSETS.arrowRight} className='rotate-180' alt='' />
                Back{' '}
              </button>
            </div>
            {cLoading && (
              <div className='h-20 grid place-items-center'>
                <ClipLoader color={theme.colors.primary} size={30} />
              </div>
            )}
            {subCategories.map(h => (
              <div
                key={h.id}
                className='flex items-center justify-between 2xl:h-12 h-10 border-b-[0.5px] border-grey-600 px-2 bg-grey-100 cursor-pointer'
                onClick={() => handleSelectSubCategory(h)}
              >
                <p className='2xl:text-lg text-base font-medium'>{h.title}</p>
                <img src={ASSETS.arrowRight} alt='' />
              </div>
            ))}
          </>
        )}
      </div>
      {selectedCategory && selectedSubCategory && (
        <>
          <p className='text-lg font-medium 2xl:mt-[34px] mt-5 2xl:mb-[38px] mb-6'>
            {selectedSubCategory.title}
          </p>
          <div className='flex flex-col 2xl:gap-5 gap-4'>
            {qLoading && (
              <div className='h-20 grid place-items-center'>
                <ClipLoader color={theme.colors.primary} size={30} />
              </div>
            )}
            {questions.map(q => (
              <Accordion question={q.question} answer={q.answer} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default HelpCenter;
