import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useBlocker, useNavigate } from 'react-router-dom';
import { RootState, Dispatch } from 'store';
import Button from 'components/Button/Button';
import SaveProgress from 'Dialogs/SaveProgress/SaveProgress';
import ASSETS from 'assets';
import { uploadVideo } from 'http/videoService';
import { t } from 'i18next';
import { applyJob } from 'http/jobService';
import Draft from 'Dialogs/Draft/Draft';
import { IDraft, IVideoQuestion } from 'types/interfaces';
import { ROLES } from 'types/types';
import { CONSTANTS } from 'app.config';

const formatTime = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);

  return `${hours < 10 ? `0${hours}` : hours}:${
    minutes < 10 ? `0${minutes}` : minutes
  }:${secs < 10 ? `0${secs}` : secs}`;
};

const VideoMerge = () => {
  const navigate = useNavigate();
  const dispatch: Dispatch = useDispatch();
  const [shouldBlock, setShouldBlock] = useState(true);

  const { finalVideo, data, thumbnail } = useSelector(
    (state: RootState) => state.video
  );
  const { path } = useSelector((state: RootState) => state.utils);
  const { user } = useSelector((state: RootState) => state.auth);
  const {
    applicant_id,
    job,
    applyQuestionDetails,
    salary_range,
    selectedJob,
    details,
    questions,
  } = useSelector((state: RootState) => state.job);
  const { selected: company } = useSelector(
    (state: RootState) => state.companies
  );

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (
      nextLocation.pathname !== '/upload-or-record' &&
      nextLocation.pathname !== '/apply' &&
      shouldBlock &&
      (path === 'apply' || path === 'create-job')
    ) {
      return true;
    } else {
      return false;
    }
  });

  const progressRef = useRef<HTMLDivElement>(null);

  const [videoSrc, setVideoSrc] = useState('');
  const [frames, setFrames] = useState<string[]>([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [timeR, setTimeR] = useState('00:00');

  const [videoPortions, setVideoPortions] = useState<
    { width: number; left: number }[]
  >([]);

  const [time, setTime] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [openSaveModal, setOpenSaveModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const videoRef = useRef<HTMLVideoElement>(null);
  const frameRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleTimeUpdate = () => {
    if (videoRef.current && progressRef.current) {
      setTime(videoRef.current.currentTime);
      const duration = videoRef.current.duration;
      const videoTime = videoRef.current.currentTime;
      const left = (videoTime / duration) * 100;
      progressRef.current.style.left = `${left}%`;
      const time = Math.floor(videoTime);
      const minutes = Math.floor(time / 60);
      const seconds = time % 60;
      setTimeR(
        `${minutes < 10 ? '0' : ''}${minutes}:${
          seconds < 10 ? '0' : ''
        }${seconds}`
      );
    }
    if (
      videoRef.current &&
      videoRef.current.currentTime === videoRef.current.duration
    ) {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleCancel = () => {
    if (blocker.proceed) {
      blocker.proceed();
    }
  };

  const handleContinue = () => {
    const draftList: IDraft[] = JSON.parse(
      localStorage.getItem(CONSTANTS.DRAFTS) || '[]'
    );

    if (path === 'apply') {
      const dData: any = {};
      const draft: any = {};

      const q: IVideoQuestion[] = data.map((d: IVideoQuestion) => {
        return {
          duration: d.duration,
          question: d.question,
          id: d.id,
          media: null,
          orientations: '',
        };
      });

      dData.application_id = applicant_id;
      dData.job_id = job!.id;
      dData.job = job!;
      dData.type = 'apply-job';
      dData.step = 'video';
      dData.applyQuestionDetails = applyQuestionDetails;
      dData.video_questions = q;
      dData.salary_range = salary_range;

      draft.id = `${user!.id}${user!.role}${applicant_id}`;
      draft.data = dData;
      draft.role = user!.role as ROLES;
      draft.type = 'apply-job';
      draft.user_id = user!.id;

      if (draftList.filter((d: IDraft) => d.id === draft.id).length === 0) {
        draftList.push(draft);
        localStorage.setItem(CONSTANTS.DRAFTS, JSON.stringify(draftList));
      } else {
        const index = draftList.findIndex((d: IDraft) => d.id === draft.id);
        draftList[index] = draft;
        localStorage.setItem(CONSTANTS.DRAFTS, JSON.stringify(draftList));
      }
    } else if (path === 'create-job') {
      // @ts-ignore
      const dData: ICreateJobDraft = {};
      const draft: any = {};

      const q: IVideoQuestion[] = data.map((d: IVideoQuestion) => {
        return {
          duration: d.duration,
          question: d.question,
          id: d.id,
          media: null,
          orientations: '',
        };
      });
      dData.company = company!;
      dData.company_id = company!.company_id;
      dData.job_details = details!;
      dData.step = 'video';
      dData.type = 'create-job';
      dData.questions = questions!;
      dData.video_questions = q;
      dData.job_id = selectedJob;
      draft.id = `${user!.id}${user!.role}${company?.company_id}${
        details?.title
      }`;
      draft.data = dData;
      draft.role = user!.role as ROLES;
      draft.type = 'create-job';
      draft.user_id = user!.id;
      if (draftList.filter((d: IDraft) => d.id === draft.id).length === 0) {
        draftList.push(draft);
        localStorage.setItem(CONSTANTS.DRAFTS, JSON.stringify(draftList));
      } else {
        const index = draftList.findIndex((d: IDraft) => d.id === draft.id);
        draftList[index] = draft;
        localStorage.setItem(CONSTANTS.DRAFTS, JSON.stringify(draftList));
      }
    }

    handleCancel();
  };

  const handlePlayVideo = useCallback(() => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  }, [isPlaying]);

  const handleUpload = async () => {
    try {
      setShouldBlock(false);
      setLoading(true);

      const drafts: IDraft[] = JSON.parse(
        localStorage.getItem(CONSTANTS.DRAFTS) || '[]'
      );

      const videoData = new FormData();

      data.forEach((e, index) => {
        let t = 0;
        let obj = {
          question: e.question,
          end_time: t + +e.duration,
          order: index,
        };
        videoData.append(`questions[${index}][question]`, obj.question);
        videoData.append(
          `questions[${index}][end_time]`,
          obj.end_time.toString()
        );
        videoData.append(`questions[${index}][order]`, obj.order.toString());
        t += +e.duration;
      });

      videoData.append('video', finalVideo as Blob);
      videoData.append('thumbnail', thumbnail as Blob);

      if (user?.role) {
        videoData.append('role', user!.role);
      }

      if (path === 'apply') {
        const { data } = await applyJob(
          {
            ...applyQuestionDetails,
            salary_range_from: salary_range[0],
            salary_range_to: salary_range[1],
            job_id: job!.id,
          },
          applicant_id
        );
        videoData.append('videoType', '2');
        videoData.append('application_id', data.data.id);
      } else if (path === 'create-job' && selectedJob) {
        const id = `${user!.id}${user!.role}${company?.company_id}${
          details?.title
        }`;
        if (drafts.find(d => d.id === id)) {
          drafts.splice(
            drafts.findIndex(d => d.id === id),
            1
          );
        }
        localStorage.setItem(CONSTANTS.DRAFTS, JSON.stringify(drafts));
        videoData.append('videoType', '1');
        videoData.append('job_id', selectedJob);
      } else {
        videoData.append('videoType', '0');
      }

      await uploadVideo(videoData);
      dispatch.auth.handleGetCurrentUser();

      if (finalVideo) {
        dispatch.video.setFinalVideo(null);
      }

      if (path === 'apply') {
        navigate('/application-submit', { replace: true });
      }
      if (path === 'create-job') {
        navigate('/explore');
      } else {
        if (!user?.job_title) {
          navigate('/make-profile/step-1', { replace: true });
        } else if (!user?.userDetails?.id) {
          navigate('/make-profile/step-4', { replace: true });
        } else {
          navigate('/explore', { replace: true });
        }
      }
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
      setShouldBlock(true);
    }
  };

  const handleCaptureFrames = async () => {
    if (frames.length === 0 && frameRef.current && canvasRef.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      const video = frameRef.current;
      const duration = video.duration;
      const totalFrames = 8;
      const framesArray: string[] = [];
      const interval = duration / (totalFrames - 1);
      setTotalTime(duration);
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      for (let i = 0; i < totalFrames; i++) {
        const time = i * interval;
        video.currentTime = time;

        await new Promise<void>(resolve => {
          const handleSeeked = () => {
            context!.drawImage(video, 0, 0, canvas.width, canvas.height);
            framesArray.push(canvas.toDataURL('image/png'));
            video.removeEventListener('seeked', handleSeeked);
            resolve();
          };
          video.addEventListener('seeked', handleSeeked);
        });
      }

      setFrames(framesArray);

      const portions: { width: number; left: number }[] = [];
      let left = 0;

      data.forEach(v => {
        const width = (+v.duration / duration) * 100;
        portions.push({ width, left });
        left += width;
      });

      setVideoPortions(portions);
    }
  };

  useEffect(() => {
    if (finalVideo) {
      setVideoSrc(URL.createObjectURL(finalVideo));
    }
  }, [finalVideo]);

  return (
    <div className='w-full flex-grow'>
      <div className='w-full 2xl:max-w-[620px] max-w-[410px] mx-auto bg-[#00000099] h-full max-h-[824px] relative aspect-[0.75/1]'>
        {videoSrc && (
          <>
            <video
              ref={frameRef}
              src={videoSrc}
              className='w-full h-full object-contain hidden'
              onLoadedData={handleCaptureFrames}
            />
            <video
              src={videoSrc}
              className='w-full h-full object-contain'
              ref={videoRef}
              onClick={handlePlayVideo}
              onTimeUpdate={handleTimeUpdate}
            ></video>
          </>
        )}
        <div
          className='absolute left-[50%] top-[50%] transform translate-x-[-50%] translate-y-[-50%] size-10 rounded-full grid place-items-center bg-[#0000004d] cursor-pointer'
          onClick={handlePlayVideo}
        >
          <img
            src={isPlaying ? ASSETS.pause : ASSETS.play}
            className=' w-5 shadow-2xl '
            alt=''
          />
        </div>
        <div className='absolute 2xl:bottom-9 bottom-4 2xl:px-[33px] px-4 w-full'>
          <Button
            label={t(
              path === 'apply'
                ? 'Submit Application'
                : path === 'create-job'
                ? 'Submit Posting'
                : 'Upload'
            )}
            onClick={handleUpload}
            className='w-full 2xl:!text-22 !text-lg'
            loading={loading}
          />
        </div>
        <div className='absolute w-full top-0 left-0 2xl:px-6 px-3 2xl:pt-7 pt-5'>
          <div className='flex items-center justify-between'>
            <div
              className='2xl:size-[54px] size-[35px] rounded-full bg-[#0000001a] grid place-items-center cursor-pointer'
              onClick={handleGoBack}
            >
              <img src={ASSETS.cross} className='w-5 2xl:w-auto' alt='' />
            </div>
          </div>
          <div className='flex items-center justify-between mt-[14px]'>
            <p className='2xl:text-xl text-base font-medium '>
              {formatTime(time)}
            </p>
            <p className='2xl:text-xl text-base font-medium '>
              {formatTime(totalTime)}
            </p>
          </div>
          <div className='relative w-full' ref={parentRef}>
            <div className='flex items-center gap-[2px] 2xl:h-[85px] h-14 2xl:rounded-[15px] rounded-lg relative'>
              <div
                className='absolute left-0 translate-x-[-50%] bg-primary 2xl:top-[-60px] top-[-40px] px-3 py-[6px] rounded-3xl before:content-[""] before:absolute before:w-[3px] z-10 2xl:before:h-[120px] before:h-[70px] before:bg-primary before:bottom-0 before:translate-y-[100%] before:left-[calc(50%_-_1.5px)] transition-all  duration-100'
                ref={progressRef}
              >
                <p className='2xl:text-[20px] text-sm text-white font-medium '>
                  {timeR}
                </p>
              </div>
              {frames.map((frame, index) => (
                <div
                  className='flex-1 h-full rounded-md overflow-hidden'
                  key={index}
                >
                  <img
                    src={frame}
                    alt={`frame-${index}`}
                    className='h-full w-full object-cover'
                  />
                </div>
              ))}
              <div className='absolute top-0 left-[0] w-full h-full flex items-center'>
                {videoPortions.map((v, i) => (
                  <div
                    key={i}
                    className={`relative h-full 2xl:border-[3px] border-2 border-white cursor-move 2xl:rounded-[12px] rounded-lg before:content-[""] [&:last-of-type]:flex-grow before:absolute before:top-[50%] before:left-0 before:translate-x-[-60%] before:translate-y-[-50%] before:w-[10px] before:h-[10px] before:bg-white before:rounded-full after:absolute after:top-[50%] after:right-0 after:translate-x-[60%] after:translate-y-[-50%] after:w-[10px] after:h-[10px] after:bg-white after:rounded-full`}
                    style={{ width: `${v.width}%` }}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <canvas ref={canvasRef} style={{ display: 'none' }} />
      {openSaveModal && (
        <SaveProgress open={openSaveModal} setOpen={setOpenSaveModal} />
      )}
      {blocker.state === 'blocked' && (
        <Draft
          message='Your progress may be lost. Want to save in Drafts?'
          onCancel={handleCancel}
          onContinue={handleContinue}
        />
      )}
    </div>
  );
};

export default VideoMerge;
