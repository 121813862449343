import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { IVideo } from 'types/interfaces';
import { getAllVideos } from 'http/videoService';

interface IState {
  loading: boolean;
  posts: IVideo[];
}

export const posts = createModel<RootModel>()({
  name: 'posts',
  state: {
    loading: false,
    posts: [],
  } as IState,
  reducers: {
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setPosts(state, payload: IVideo[]) {
      state.posts = payload;
    },
  },
  effects: dispatch => ({
    async handleGetVideos(payload: string, state) {
      try {
        const user = state.auth.user;

        dispatch.posts.setLoading(true);
        const { data } = await getAllVideos(payload || '');
        if (user?.role === 'A') {
          const p: IVideo[] = [];
          data.data.videos.forEach(v => {
            if (!v.is_applied) {
              p.push(v);
            }
          });
          dispatch.posts.setPosts(p);
        } else {
          dispatch.posts.setPosts(data.data.videos);
        }
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.posts.setLoading(false);
      }
    },
  }),
});
