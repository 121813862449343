import React, { useEffect, useState } from 'react';

import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile } from '@ffmpeg/util';

import {
  closestCorners,
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { useBlocker, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';

import Button from 'components/Button/Button';
import UploadOrRecordVideo from 'Dialogs/UploadOrRecordVideo/UploadOrRecordVideo';

import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import Item from './Item';
import RecordingTips from 'Dialogs/RecordingTips/RecordingTips';
import {
  restrictToVerticalAxis,
  restrictToParentElement,
} from '@dnd-kit/modifiers';
import { t } from 'i18next';
import { CONSTANTS } from 'app.config';
import { IDraft, IVideoQuestion } from 'types/interfaces';
import { ROLES } from 'types/types';
import Draft from 'Dialogs/Draft/Draft';

const ffmpeg = new FFmpeg();

const UploadOrRecord = () => {
  const dispatch = useDispatch<Dispatch>();
  const [open, setOpen] = useState(false);
  const [tips, setTips] = useState(false);
  const [loading, setLoading] = useState(false);
  const [libLoading, setLibLoading] = useState(true);
  const navigate = useNavigate();
  const { data, finalVideo, thumbnail } = useSelector(
    (state: RootState) => state.video
  );
  const { path } = useSelector((state: RootState) => state.utils);
  const { user } = useSelector((state: RootState) => state.auth);
  const {
    applicant_id,
    job,
    applyQuestionDetails,
    salary_range,
    selectedJob,
    details,
    questions,
  } = useSelector((state: RootState) => state.job);
  const { selected: company } = useSelector(
    (state: RootState) => state.companies
  );

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (
      nextLocation.pathname !== '/video-trimmer' &&
      nextLocation.pathname !== '/create-video' &&
      nextLocation.pathname !== '/video-merge' &&
      (path === 'apply' || path === 'create-job')
    ) {
      return true;
    } else {
      return false;
    }
  });
  const [progress, setProgress] = useState(50);

  const [fallBack, setFallBack] = useState(false);

  const getPosition = (id: any) => {
    return data.findIndex(i => i.id === id);
  };

  const [disableNext, setDisableNext] = useState(false);

  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);

  const sensors = useSensors(mouseSensor, touchSensor);

  const handleDrag = (e: DragEndEvent) => {
    const { active, over } = e;
    const originalPos = getPosition(active.id);
    const newPos = getPosition(over?.id);
    const newList = arrayMove(data, originalPos, newPos);
    dispatch.video.setData(newList);
    if (finalVideo) {
      dispatch.video.setFinalVideo(null);
    }
  };

  const handleMergeVideoFallback = async () => {
    try {
      // if (finalVideo && thumbnail) {
      //   return navigate('/video-merge');
      // }

      setFallBack(true);

      setLoading(true);
      const videos = data.map(d => d.media);
      setProgress(0);

      // Clear previous files and result
      ffmpeg.deleteFile('input.txt').catch(() => {});
      ffmpeg.deleteFile('output.mp4').catch(() => {});
      dispatch.video.setFinalVideo(null);

      console.log('Normalizing videos...');

      const path = 'input.mp4';
      if (!thumbnail) {
        ffmpeg.writeFile(path, await fetchFile(videos[0]!));

        await ffmpeg.exec([
          '-i',
          path,
          '-ss',
          '00:00:10',
          '-vframes',
          '1',
          'outputThumb.png',
        ]);
        const dd = await ffmpeg.readFile('outputThumb.png');
        const _thumbnail = new Blob([dd], { type: 'image/png' });
        const t = new File([_thumbnail], 'thumbnail.png', {
          type: 'image/png',
        });

        dispatch.video.setThumbnail(t);
      }

      // Normalize videos
      await Promise.all(
        videos.map(async (v, index) => {
          try {
            const inputName = `input${index + 1}.mp4`;
            const normalizedName = `normalized${index + 1}.mp4`;
            console.log(`Normalizing video ${index + 1}`);

            ffmpeg.writeFile(inputName, await fetchFile(v!));
            await ffmpeg.exec([
              '-i',
              inputName,
              '-c:v',
              'libx264', // Video codec
              '-c:a',
              'aac', // Audio codec
              '-vf',
              'scale=640:480', // Resolution (adjust as needed)
              '-r',
              // '-crf',
              // '25',
              // '-preset',
              // 'veryfast',
              '30', // Frame rate (adjust as needed)
              '-b:a',
              '128k', // Audio bitrate (adjust as needed)
              normalizedName,
            ]);
            ffmpeg.deleteFile(inputName);
            console.log(`Video ${index + 1} normalized successfully.`);
            setProgress(prev => prev + 25);
          } catch (error) {
            console.error(`Error normalizing video ${index + 1}:`, error);
          }
        })
      );

      console.log('Normalization done.');

      console.log('Writing input list file...');

      // Write the input list file
      const inputList = videos
        .map((_, index) => `file normalized${index + 1}.mp4`)
        .join('\n');
      ffmpeg.writeFile('input.txt', inputList);

      console.log('Concatenating videos...');

      await ffmpeg.exec([
        '-f',
        'concat',
        '-safe',
        '0',
        '-i',
        'input.txt',
        '-c',
        'copy',
        'output.mp4',
      ]);

      console.log('Reading output file...');

      // Read the output file
      const d = await ffmpeg.readFile('output.mp4');
      const blob = new Blob([d], { type: 'video/mp4' });
      const file = new File([blob], 'video.mp4', {
        type: 'video/mp4',
      });
      // const mergedVideoURL = URL.createObjectURL(blob);
      dispatch.video.setFinalVideo(file);

      console.log('Cleanup...');

      // Clean up FFmpeg virtual files
      videos.forEach((_, index) =>
        ffmpeg.deleteFile(`normalized${index + 1}.mp4`)
      );
      ffmpeg.deleteFile('input.txt');
      ffmpeg.deleteFile('output.mp4');
      ffmpeg.deleteFile('outputThumb.png');

      console.log('Merging completed successfully.');

      navigate('/video-merge');
    } catch (err: any) {
      console.log('Error during merging:', err);
    } finally {
      setLoading(false);
      setFallBack(false);
    }
  };

  const handleMergeVideo = async () => {
    const t1 = new Date().getTime();
    try {
      setLoading(true);
      const videos = data.map(d => d.media);
      setProgress(0);

      // Clear previous files and result
      await ffmpeg.deleteFile('input.txt').catch(() => {});
      await ffmpeg.deleteFile('output.mp4').catch(() => {});
      dispatch.video.setFinalVideo(null);

      console.log('Normalizing and merging videos...');

      // Write all input videos to FFmpeg's virtual file system
      await Promise.all(
        videos.map(async (v, index) => {
          const inputName = `input${index + 1}.mp4`;
          ffmpeg.writeFile(inputName, await fetchFile(v!));
        })
      );

      console.log('Generating thumbnail from the first video...');

      // Generate a thumbnail from the first video
      await ffmpeg.exec([
        '-i',
        'input1.mp4',
        '-ss',
        '00:00:10',
        '-vframes',
        '1',
        'outputThumb.png',
      ]);
      const dd = await ffmpeg.readFile('outputThumb.png');
      const _thumbnail = new Blob([dd], { type: 'image/png' });
      const t = new File([_thumbnail], 'thumbnail.png', { type: 'image/png' });

      dispatch.video.setThumbnail(t);

      await ffmpeg.deleteFile('outputThumb.png');

      console.log('Merging and normalizing videos...');

      // Dynamically generate filter_complex string
      const filterComplexParts: string[] = [];
      const videoInputs: string[] = [];
      const audioInputs: string[] = [];

      videos.forEach((_, index) => {
        const orientations = data[index].orientations;
        if (orientations === 'landscape') {
          filterComplexParts.push(
            `[${index}:v]scale=480:-1,pad=480:850:(480-iw)/2:(850-ih)/2,setdar=9/16[v${index}]`
          );
        } else {
          filterComplexParts.push(
            `[${index}:v]scale=480:850,setdar=9/16[v${index}]`
          );
        }
        videoInputs.push(`[v${index}]`);
        audioInputs.push(`[${index}:a]`);
      });

      const filterComplex = `
        ${filterComplexParts.join(';')};
        ${audioInputs.join('')}concat=n=${videos.length}:v=0:a=1[a];
        ${videoInputs.join('')}concat=n=${videos.length}:v=1:a=0[v]
      `;

      // Run FFmpeg command to normalize and merge videos in a single step
      await ffmpeg
        .exec([
          '-v',
          'debug',
          ...videos.map((_, index) => ['-i', `input${index + 1}.mp4`]).flat(),
          '-filter_complex',
          filterComplex,
          '-map',
          '[v]',
          '-map',
          '[a]',
          '-c:v',
          'libx264',
          '-crf',
          '25',
          '-preset',
          'veryfast',
          '-c:a',
          'aac',
          '-b:a',
          '128k',
          '-r',
          '30',
          '-vsync',
          '2',
          '-movflags',
          '+faststart',
          'output.mp4',
        ])
        .catch(err => console.log('Error in command execution', err));

      console.log('Reading output file...');

      // Read the output file
      const d = await ffmpeg.readFile('output.mp4');
      const blob = new Blob([d], { type: 'video/mp4' });
      const file = new File([blob], 'video.mp4', {
        type: 'video/mp4',
      });

      dispatch.video.setFinalVideo(file);

      console.log('Cleanup...');

      // Clean up FFmpeg virtual files
      await Promise.all(
        videos.map(async (_, index) =>
          ffmpeg.deleteFile(`input${index + 1}.mp4`)
        )
      );
      await ffmpeg.deleteFile('output.mp4');

      console.log('Merging completed successfully.');

      navigate('/video-merge');
      setLoading(false);
    } catch (err) {
      console.log('Error during merging:', err);
      console.log('Converting to fallback method');
      handleMergeVideoFallback();
    } finally {
      const t2 = new Date().getTime();
      console.log((t2 - t1) / 1000);
    }
  };

  const handleCancel = () => {
    if (blocker.proceed) {
      blocker.proceed();
    }
  };

  const handleContinue = () => {
    const draftList: IDraft[] = JSON.parse(
      localStorage.getItem(CONSTANTS.DRAFTS) || '[]'
    );

    if (path === 'apply') {
      const dData: any = {};
      const draft: any = {};

      const q: IVideoQuestion[] = data.map((d: IVideoQuestion) => {
        return {
          duration: d.duration,
          question: d.question,
          id: d.id,
          media: null,
          orientations: '',
        };
      });

      dData.application_id = applicant_id;
      dData.job_id = job!.id;
      dData.job = job!;
      dData.type = 'apply-job';
      dData.step = 'video';
      dData.applyQuestionDetails = applyQuestionDetails;
      dData.video_questions = q;
      dData.salary_range = salary_range;

      draft.id = `${user!.id}${user!.role}${applicant_id}`;
      draft.data = dData;
      draft.role = user!.role as ROLES;
      draft.type = 'apply-job';
      draft.user_id = user!.id;

      if (draftList.filter((d: IDraft) => d.id === draft.id).length === 0) {
        draftList.push(draft);
        localStorage.setItem(CONSTANTS.DRAFTS, JSON.stringify(draftList));
      } else {
        const index = draftList.findIndex((d: IDraft) => d.id === draft.id);
        draftList[index] = draft;
        localStorage.setItem(CONSTANTS.DRAFTS, JSON.stringify(draftList));
      }
    } else if (path === 'create-job') {
      // @ts-ignore
      const dData: ICreateJobDraft = {};
      const draft: any = {};

      const q: IVideoQuestion[] = data.map((d: IVideoQuestion) => {
        return {
          duration: d.duration,
          question: d.question,
          id: d.id,
          media: null,
          orientations: '',
        };
      });
      dData.company = company!;
      dData.company_id = company!.company_id;
      dData.job_details = details!;
      dData.step = 'video';
      dData.type = 'create-job';
      dData.questions = questions!;
      dData.video_questions = q;
      dData.job_id = selectedJob;
      draft.id = `${user!.id}${user!.role}${company?.company_id}${
        details?.title
      }`;
      draft.data = dData;
      draft.role = user!.role as ROLES;
      draft.type = 'create-job';
      draft.user_id = user!.id;
      if (draftList.filter((d: IDraft) => d.id === draft.id).length === 0) {
        draftList.push(draft);
        localStorage.setItem(CONSTANTS.DRAFTS, JSON.stringify(draftList));
      } else {
        const index = draftList.findIndex((d: IDraft) => d.id === draft.id);
        draftList[index] = draft;
        localStorage.setItem(CONSTANTS.DRAFTS, JSON.stringify(draftList));
      }
    }

    handleCancel();
  };

  const handleCamerRecord = () => {
    setTips(true);
  };

  const handleStartRecording = () => {
    navigate('/record-from-camera');
  };

  useEffect(() => {
    let hasError = false;

    data.forEach(item => {
      if (!item.media) {
        hasError = true;
      }
    });

    setDisableNext(hasError);
  }, [data]);

  useEffect(() => {
    if (!ffmpeg.loaded) {
      ffmpeg
        .load()
        .then(() => {
          console.log('FFMPEG loaded');
          setLibLoading(false);
        })
        .catch((err: any) => console.log(err.message));
    } else {
      setLibLoading(false);
    }
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      if (!loading) return;
      e.preventDefault();
      e.returnValue = '';
      return '';
      // }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    // eslint-disable-next-line
  }, [loading]);

  return (
    <div className='w-full flex-grow overflow-auto'>
      <div className='w-full max-w-[776px] bg-white 2xl:p-[35px] p-5 mx-auto border border-grey-600 rounded'>
        <p className='2xl:text-32 text-2xl text-primary'>
          {t('Upload or Record a Video')}
        </p>
        <div className='flex flex-col gap-2 2xl:mt-9 mt-5'>
          <DndContext
            collisionDetection={closestCorners}
            onDragEnd={handleDrag}
            sensors={sensors}
            modifiers={[restrictToVerticalAxis, restrictToParentElement]}
          >
            <SortableContext
              items={data}
              strategy={verticalListSortingStrategy}
            >
              {data.map((question, index) => (
                <Item
                  key={question.id}
                  index={index}
                  question={question}
                  setOpen={setOpen}
                />
              ))}
            </SortableContext>
          </DndContext>
        </div>
        <Button
          label={t('Next')}
          className='w-full mt-9 2xl:!text-22 !text-lg'
          onClick={handleMergeVideo}
          disabled={disableNext || loading || libLoading}
          loading={loading}
        />
        {fallBack && loading && (
          <>
            <div className='w-full h-3 rounded-lg border border-grey-400 mt-4 bg-grey-50'>
              <div
                className='h-full rounded-lg bg-primary transition-all duration-300'
                style={{ width: `${progress}%` }}
              ></div>
            </div>
            <div className='w-full flex justify-center mt-1'>
              <p className='text-lg'>{progress}%</p>
            </div>
          </>
        )}

        {open && (
          <UploadOrRecordVideo
            open={open}
            setOpen={setOpen}
            onRecordFromCamera={handleCamerRecord}
          />
        )}
        {tips && (
          <RecordingTips
            open={tips}
            setOpen={setTips}
            onStartRecording={handleStartRecording}
          />
        )}
      </div>
      {blocker.state === 'blocked' && (
        <Draft
          message='Your progress may be lost. Want to save in Drafts?'
          onCancel={handleCancel}
          onContinue={handleContinue}
        />
      )}
    </div>
  );
};

export default UploadOrRecord;
