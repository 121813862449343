import { IApiResponse } from 'types/api';
import api from './index';
import {
  IFAQ,
  IHelpCategory,
  IHelpHotline,
  IHelpQuestion,
} from 'types/interfaces';

export const getContent = (type: number) =>
  api.get<
    IApiResponse<{
      id: string;
      content: string;
      type: number;
    }>
  >(`/v1/content?type=${type}`);

export const getFaqs = () => api.get<IApiResponse<IFAQ[]>>('/v1/faqs/all');

export const getAllHelpCategories = () =>
  api.get<IApiResponse<IHelpCategory[]>>('/v1/help-center-category');

export const getAllHelpSubCategories = (parentId: string) =>
  api.get<IApiResponse<IHelpCategory[]>>(
    `/v1/help-center-category/${parentId}/sub`
  );

export const getAllQuestions = (categoryId: string) =>
  api.get<IApiResponse<IHelpQuestion[]>>(`/v1/help-center/all/${categoryId}`);

export const getHelpCenterHotlines = () =>
  api.get<IApiResponse<IHelpHotline[]>>('/v1/help-center/hotlines');
