import { createModel } from '@rematch/core';
import { RootModel } from '.';
import {
  IAppliedJob,
  IChat,
  IExtraParams,
  IReceivedJobs,
  IUser,
} from 'types/interfaces';
import { applicantRoles } from 'app.config';
import { appliedJobs, receivedJobApplications } from 'http/jobService';
import { AgoraChat } from 'agora-chat';
import { getChats } from 'http/userService';

export type Chat = (IReceivedJobs | IAppliedJob) & IExtraParams;

interface IState {
  loading: boolean;
  chats: Chat[];
  selected: Chat | null;
  selectedSChat: IChat | null;
  count: number;
  sChats: IChat[];
}

export const chat = createModel<RootModel>()({
  name: 'chat',
  state: {
    loading: false,
    chats: [],
    selected: null,
    selectedSChat: null,
    count: 0,
    sChats: [],
  } as IState,
  reducers: {
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setChats(state, payload: Chat[]) {
      state.chats = payload;
    },
    setSelected(state, payload: Chat | null) {
      state.selected = payload;
      state.selectedSChat = null;
    },
    setSelectedSChat(state, payload: IChat | null) {
      state.selectedSChat = payload;
      state.selected = null;
    },
    updateChat(state, payload: Chat) {
      const index = state.chats.findIndex(c => c.group_id === payload.group_id);
      if (index !== -1) {
        state.chats[index] = payload;
      }
    },
    addChat(state, payload: Chat) {
      state.chats.push(payload);
    },
    resetChats(state) {
      state.chats = [];
    },
    setCount(state, payload: number) {
      state.count = payload;
    },
    incrementCount(state) {
      state.count += 1;
    },
    decrementCount(state) {
      state.count -= 1;
    },
    setSChats(state, payload: IChat[]) {
      state.sChats = payload;
    },
  },
  effects: dispatch => ({
    async handleFetchChats(payload: {
      user: IUser;
      conn: AgoraChat.Connection;
    }) {
      try {
        const { user, conn } = payload;
        dispatch.chat.setLoading(true);
        const role = user.role;
        const list: Chat[] = [];
        if (role) {
          if (applicantRoles.includes(role)) {
            const { data } = await appliedJobs();
            list.push(...(data.data as Chat[]));
          } else {
            const { data } = await receivedJobApplications();
            list.push(...(data.data as Chat[]));
          }
          const data = await conn.getChatThreadLastMessage({
            chatThreadIds: [...list.map(l => l.group_id)],
          });

          console.log(data);

          const d = await conn.getServerConversations({
            pageSize: 50,
            cursor: '',
          });

          const convos = d.data?.conversations;

          convos?.forEach(c => {
            const index = list.findIndex(l => l.group_id === c.conversationId);
            if (list[index]) {
              list[index].lastMsg = c.lastMessage;
              list[index].unreadCount = c.unReadCount;
            }
          });

          const sortedItems = list.sort((a, b) => {
            const timestampA = a.lastMsg?.time ?? null;
            const timestampB = b.lastMsg?.time ?? null;

            // Place items with null timestamps at the bottom
            if (timestampA === null && timestampB !== null) return 1;
            if (timestampA !== null && timestampB === null) return -1;

            // If both timestamps are null, keep their order
            if (timestampA === null && timestampB === null) return 0;

            // Sort by timestamp if both are non-null
            return timestampB! - timestampA!;
          });

          dispatch.chat.setChats(sortedItems);
        }
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.chat.setLoading(false);
      }
    },
    async handleFetchSChats() {
      try {
        const { data } = await getChats();
        dispatch.chat.setSChats(data.data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.chat.setLoading(false);
      }
    },
  }),
});
