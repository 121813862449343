export enum filters {
  sortyBy = 'openSortBy',
  location = 'openLocation',
  jobTitle = 'openJobTitle',
  tags = 'openTags',
  companyName = 'openCompanyName',
  areaOfExperties = 'openAreaOfExperties',
  salaryRange = 'openSalaryRange',
  jobLevel = 'openJobLevel',
  companySize = 'openCompanySize',
  jobType = 'openJobType',
}

export enum ROLES {
  'Applicant' = 'A',
  'Recruiting Agent for Applicant' = 'RA',
  'Staffing Agent for Applicant' = 'SA',
  'Employer' = 'E',
  'Recruiting Agent for Employer' = 'RE',
  'Staffing Agent for Employer' = 'SE',
}

export enum ContentTypes {
  PRIVACY_POLICY = 0,
  COOKIES_POLICY = 1,
  ABOUT = 2,
  INAPPROPRIATE_CONTENT = 3,
  TERMS_AND_CONDITIONS = 4,
}
