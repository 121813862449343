import React, { useState } from 'react';

import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'store/index';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button/Button';

import ASSETS from 'assets';
import GoPremium from 'Dialogs/GoPremium/GoPremium';
import SortBy from './SortBy';
import Location from './Location';
import JobLevel from './JobLevel';
import SalaryRange from './SalaryRange';
import JobTitle from './JobTitle';
import Tags from './Tags';
import CompanyName from './CompanyName';
import AreaOfExperties from './AreaOfExperties';
import { applicantRoles, employerRoles } from 'app.config';

const Filters = () => {
  const dispatch = useDispatch<Dispatch>();

  const [t] = useTranslation();

  const {
    sortBy,
    location,
    jobTitle,
    tags,
    jobLevel,
    companies,
    areaOfExperties,
    filterApplied,
  } = useSelector((state: RootState) => state.filters);

  const { user } = useSelector((state: RootState) => state.auth);

  const [openGoPremium, setOpenGoPremium] = useState(false);
  const [filtersVisible, setFiltersVisible] = useState(false);

  const togleFilters = () => setFiltersVisible(!filtersVisible);

  const handleClearFilters = () => {
    dispatch.filters.reset();
  };

  const handleGetFilterCount = () => {
    let count = 0;
    if (user?.role) {
      if (sortBy) count++;
    }
    count += location.length ? 1 : 0;
    count += jobTitle.length ? 1 : 0;
    count += tags.length ? 1 : 0;
    count += jobLevel.length ? 1 : 0;
    count += companies.length ? 1 : 0;
    count += areaOfExperties.length ? 1 : 0;
    if (!employerRoles.includes(user?.role || '')) {
      if (filterApplied >= 1) {
        count++;
      }
    }
    return count;
  };

  // const handleOpenFilter = (filter: string) => {
  //   dispatch.filters.openFilter(filter);
  // };

  return (
    <>
      <Container className='w-full mt-2'>
        <div className='header border border-grey-600 rounded-md w-full bg-white px-2 py-2 flex items-center justify-between'>
          <div className='flex items-center gap-[5px]' onClick={togleFilters}>
            <div className='w-8 h-8 rounded-full bg-primary grid place-items-center cursor-pointer'>
              <img src={ASSETS.filters} className='w-4' alt='' />
            </div>
            <p className='text-base font-medium'>
              {t('Filters')}{' '}
              <span className='text-placeholderColor'>
                ({handleGetFilterCount()})
              </span>
            </p>
          </div>
          <div className='flex items-center gap-1'>
            <p
              className='text-base font-medium text-placeholderColor cursor-pointer'
              onClick={handleClearFilters}
            >
              {t('Clear')}
            </p>
            {filtersVisible && (
              <img
                src={ASSETS.cross}
                className='w-6 opacity-50 cursor-pointer'
                alt=''
                onClick={togleFilters}
              />
            )}
          </div>
        </div>
        {filtersVisible && (
          <div className='pt-[5px] flex-grow flex flex-col transition-[max-height]'>
            <FiltersContainer className='border-[0.5px] border-black rounded bg-white py-4 px-[10px]'>
              <div className='flex flex-col gap-2'>
                <SortBy />
                <Location />
                <JobTitle />
                <Tags />
                <JobLevel />
                <CompanyName />
                <AreaOfExperties />
                {applicantRoles.includes(user?.role || '') && <SalaryRange />}

                <div
                  onClick={() => setOpenGoPremium(true)}
                  className='filter flex  items-center gap-2 justify-between bg-[#0000003D]  rounded-lg h-10 pl-[11px]'
                >
                  <p className='text-base font-medium'>Company Size</p>
                  <div className='flex items-center'>
                    <img src={ASSETS.chevronRight} alt='' />
                  </div>
                </div>
                <div
                  onClick={() => setOpenGoPremium(true)}
                  className='filter flex  items-center gap-2 justify-between bg-[#0000003D] rounded-lg h-10 pl-[11px]'
                >
                  <p className='text-base font-medium'>Job Type</p>
                  <div className='flex items-center'>
                    <img src={ASSETS.chevronRight} alt='' />
                  </div>
                </div>
              </div>
              <Button
                label={t('Apply')}
                className='w-full !h-11 !text-lg mt-4'
                onClick={() => {
                  dispatch.filters.setFilterApplied();
                }}
              />
            </FiltersContainer>
          </div>
        )}
        {openGoPremium && (
          <GoPremium
            open={openGoPremium}
            setOpen={setOpenGoPremium}
            title='Want more filters?'
            message='Get a premium account to unlock more filters and other features!'
          />
        )}
      </Container>
    </>
  );
};

export default Filters;

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const FiltersContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  /* overflow: auto; */
`;
