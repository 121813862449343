import React, { useRef, FC, useEffect } from 'react';
import ASSETS from 'assets';
import { t } from 'i18next';

interface IProps {
  label: string;
  children: React.ReactNode;
  reOpenWhen?: any[];
  revalidate?: boolean;
}

const Accordion: FC<IProps> = ({ label, children, reOpenWhen }) => {
  const panel = useRef<HTMLDivElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);

  const handleAccordion = () => {
    if (panel.current) {
      if (panel.current.style.maxHeight > '0px') {
        panel.current.style.maxHeight = '0px';
        panel.current.classList.remove('open');
        imgRef.current!.style.transform = 'rotate(90deg)';
        panel.current.style.overflowY = 'hidden';
      } else {
        panel.current.style.maxHeight = panel.current.scrollHeight + 'px';

        panel.current.classList.add('open');
        imgRef.current!.style.transform = 'rotate(-90deg)';
        panel.current.style.overflowY = 'auto';
      }
    }
  };

  const handleRevalidateAccordion = () => {
    setTimeout(() => {
      if (panel.current) {
        panel.current.style.maxHeight = panel.current.scrollHeight + 'px';

        panel.current.classList.add('open');
        imgRef.current!.style.transform = 'rotate(-90deg)';
        panel.current.style.overflowY = 'auto';
      }
    }, 100);
  };

  useEffect(() => {
    if (reOpenWhen?.length) {
      handleRevalidateAccordion();
    }
  }, [reOpenWhen]);

  return (
    <div className='w-full bg-grey-100 rounded-lg'>
      <div
        className='filter flex items-center gap-2 justify-between  h-10 pl-[11px] cursor-pointer'
        onClick={handleAccordion}
      >
        <p className='text-base font-medium'>{t(label)}</p>
        <div className='flex items-center'>
          <img
            src={ASSETS.chevronRight}
            className='transition duration-300 rotate-90'
            ref={imgRef}
            alt=''
          />
        </div>
      </div>
      <div
        className='panel max-h-0 overflow-hidden transition-all duration-300'
        ref={panel}
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;
