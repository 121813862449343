import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { Provider } from 'react-redux';
import { store } from './store';
import { ThemeProvider } from '@mui/material';
import THEME_MUI from 'theme/MuiTheme';

import en from './locales/en.json';
import ar from './locales/ar.json';
import fr from './locales/fr.json';
import it from './locales/it.json';
import ja from './locales/ja.json';
import ko from './locales/ko.json';
import pt from './locales/pt.json';
import zh from './locales/zh.json';
import es from './locales/es.json';
import hi from './locales/hi.json';
import ru from './locales/ru.json';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en,
      },
      ar: {
        translation: ar,
      },
      fr: {
        translation: fr,
      },
      it: {
        translation: it,
      },
      ja: {
        translation: ja,
      },
      ko: {
        translation: ko,
      },
      pt: {
        translation: pt,
      },
      zh: {
        translation: zh,
      },
      es: {
        translation: es,
      },
      ru: {
        translation: ru,
      },
      hi: {
        translation: hi,
      },
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    detection: {
      order: [
        'cookie',
        'htmlTag',
        'localStorage',
        'sessionStorage',
        'path',
        'subdomain',
      ],
      caches: ['cookie'],
    },
    react: {
      useSuspense: false,
    },
  });

const router = createBrowserRouter(
  createRoutesFromElements(<Route path='*' element={<App />} />)
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <ThemeProvider theme={THEME_MUI}>
      <RouterProvider router={router} />
    </ThemeProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
