import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { IDraft } from 'types/interfaces';
import { applicantRoles, CONSTANTS } from 'app.config';
import { ROLES } from 'types/types';

interface IState {
  loading: boolean;
  applyDrafts: IDraft[];
  createJobDrafts: IDraft[];
}

export const drafts = createModel<RootModel>()({
  name: 'drafts',
  state: {
    loading: false,
    applyDrafts: [],
    createJobDrafts: [],
  } as IState,
  reducers: {
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setApplyDrafts(state, payload: IDraft[]) {
      state.applyDrafts = payload;
    },
    setCreateJobDrafts(state, payload: IDraft[]) {
      state.createJobDrafts = payload;
    },
  },
  effects: dispatch => ({
    async handleGetDrafts(payload: { id: string; role: ROLES }) {
      const { id, role } = payload;

      try {
        dispatch.drafts.setLoading(true);

        const list: IDraft[] = JSON.parse(
          localStorage.getItem(CONSTANTS.DRAFTS) || '[]'
        );

        let d: IDraft[] = [];
        console.log(list);
        list.forEach(e => {
          if (id === e.user_id && role === e.role) {
            d.push(e);
          }
        });

        console.log(d);

        console.log(d);
        if (applicantRoles.includes(role)) {
          dispatch.drafts.setApplyDrafts(d);
        } else {
          dispatch.drafts.setCreateJobDrafts(d);
        }
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.drafts.setLoading(false);
      }
    },
  }),
});
