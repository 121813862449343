import React, { useEffect, useState } from 'react';

import { useNavigate, useBlocker } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'store';

import Button from 'components/Button/Button';
import { Range, getTrackBackground } from 'react-range';
import useCurrentBreakpoint from 'hooks/useCurrentBreakpoint';
import { t } from 'i18next';
import { CONSTANTS, jobApplyQuestions } from 'app.config';
import { IDraft } from 'types/interfaces';
import { ROLES } from 'types/types';
import Draft from 'Dialogs/Draft/Draft';

const SaleryRange = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const breakpoint = useCurrentBreakpoint();
  const [values, setValues] = useState([10, 500]);
  const { loading, job, applicant_id, applyQuestionDetails, salary_range } =
    useSelector((state: RootState) => state.job);

  const { user } = useSelector((state: RootState) => state.auth);

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (
      nextLocation.pathname !== '/create-video' &&
      nextLocation.pathname !== '/apply/job-questions'
    ) {
      return true;
    } else {
      return false;
    }
  });

  const handleCancel = () => {
    if (blocker.proceed) {
      blocker.proceed();
    }
  };

  const handleContinue = () => {
    if (blocker.proceed) {
      const draftList: IDraft[] = JSON.parse(
        localStorage.getItem(CONSTANTS.DRAFTS) || '[]'
      );

      const data: any = {};
      const draft: any = {};

      data.application_id = applicant_id;
      data.job_id = job!.id;
      data.job = job!;
      data.type = 'apply-job';
      data.step = 'salary-range';
      data.applyQuestionDetails = applyQuestionDetails;

      draft.id = `${user!.id}${user!.role}${applicant_id}`;
      draft.data = data;
      draft.role = user!.role as ROLES;
      draft.type = 'apply-job';
      draft.user_id = user!.id;

      if (draftList.filter((d: IDraft) => d.id === draft.id).length === 0) {
        draftList.push(draft);
        localStorage.setItem(CONSTANTS.DRAFTS, JSON.stringify(draftList));
      } else {
        const index = draftList.findIndex((d: IDraft) => d.id === draft.id);
        draftList[index] = draft;
        localStorage.setItem(CONSTANTS.DRAFTS, JSON.stringify(draftList));
      }
      blocker.proceed();
    }
  };

  const handleNext = () => {
    dispatch.job.setSalaryRange([values[0] * 1000, values[1] * 1000]);
    dispatch.utils.setPath('apply');
    dispatch.video.setData(jobApplyQuestions);
    navigate('/create-video');
  };

  useEffect(() => {
    if (salary_range[0] > 0 || salary_range[1] > 0) {
      setValues([salary_range[0] / 1000, salary_range[1] / 1000]);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className='w-full mx-auto max-w-[776px] flex-grow bg-white border-[0.5px] border-grey-600 rounded 2xl:py-[100px] py-[50px] 2xl:px-11 px-6 flex flex-col justify-between'>
      <div>
        <p className='2xl:text-32 text-2xl text-primary font-medium'>
          {t('Salary Range')}
        </p>
        <p className='2xl:text-lg text-base text-placeholderColor'>
          {t(
            'Expected salary range help us understand your expectations better'
          )}
        </p>
      </div>
      <div className='flex-grow 2xl:my-5 my-0 flex justify-center items-center px-4'>
        <Range
          values={values}
          onChange={values => setValues(values)}
          min={10}
          max={500}
          step={5}
          renderTrack={({ props, children }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: breakpoint === 'xl' ? '8px' : '11px',
                width: '100%',
                background: getTrackBackground({
                  values,
                  colors: ['#DAD9DB', '#008080', '#DAD9DB'],
                  min: 10,
                  max: 500,
                }),
                borderRadius: '22px',
                alignSelf: 'center',
              }}
            >
              {children}
              <div className='flex justify-between 2xl:mt-[26px] mt-5'>
                <span className='2xl:text-2xl text-xl text-placeholder'>
                  $10k
                </span>
                <span className='2xl:text-2xl text-xl text-placeholder'>
                  $500k
                </span>
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged, index }) => (
            <div
              {...props}
              style={{
                ...props.style,
              }}
              className='2xl:size-[34px] size-7 outline-none rounded-full bg-primary flex justify-center'
            >
              <div className='absolute 2xl:top-[-40px] top-[-35px] text-black font-medium 2xl:text-[26px] text-xl'>
                {`$${values[index]}`}k
              </div>
            </div>
          )}
        />
      </div>
      <Button
        label={t('Next')}
        className='2xl:!text-22 !text-lg !w-full'
        onClick={handleNext}
        loading={loading}
      />
      {blocker.state === 'blocked' && (
        <Draft
          onCancel={handleCancel}
          onContinue={handleContinue}
          message='Your progress may be lost. Want to save in Drafts?'
        />
      )}
    </div>
  );
};

export default SaleryRange;
