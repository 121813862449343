import React, { FC, useEffect, useState } from 'react';

import { Navigate, useNavigate, useBlocker } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'store';

import { useFormik } from 'formik';
import { object, boolean, InferType } from 'yup';

import Button from 'components/Button/Button';
import Checkbox from 'components/Checkbox/Checkbox';
import classNames from 'classnames';
import { ICreateJob } from 'types/api';
import { t } from 'i18next';
import Draft from 'Dialogs/Draft/Draft';
import { ICreateJobDraft, IDraft } from 'types/interfaces';
import { CONSTANTS } from 'app.config';
import { ROLES } from 'types/types';

const schema = object({
  isUSAuthorized: boolean().nullable().oneOf([true, false, null], ''),
  isSponsorWorkVisa: boolean().nullable().oneOf([true, false, null], ''),
  is_referral_name: boolean().nullable().oneOf([true, false, null], ''),
  is_referral_email: boolean().nullable().oneOf([true, false, null], ''),
  isDisability: boolean().nullable().oneOf([true, false, null], ''),
  isVeteran: boolean().nullable().oneOf([true, false, null], ''),
  isRacialBackground: boolean().nullable().oneOf([true, false, null], ''),
  isFormerEmployee: boolean().nullable().oneOf([true, false, null], ''),
  isAffiliateCompany: boolean().nullable().oneOf([true, false, null], ''),
  isReferred: boolean().nullable().oneOf([true, false, null], ''),
});
interface ICardProps {
  title: string;
  desc?: string;
  children?: React.ReactNode;
  onSkip: () => void;
  onUndoSkip: () => void;
}

export interface IJobQuestion extends InferType<typeof schema> {}

const Card: FC<ICardProps> = ({
  title,
  children,
  desc,
  onSkip,
  onUndoSkip,
}) => {
  const [skip, setSkip] = useState(false);

  const handleSkip = () => {
    setSkip(true);
    onSkip();
  };

  const handleUndoSkip = () => {
    setSkip(false);
    onUndoSkip();
  };

  return (
    <div className='bg-white border border-grey-400 2xl:pl-11 pl-7 2xl:pr-7 pr-5 2xl:pt-[34px] py-6 2xl:pb-[32px] rounded shadow-card'>
      {desc && (
        <p
          className={classNames(
            '2xl:text-2xl text-xl font-medium',
            skip && 'hidden'
          )}
        >
          {desc}
        </p>
      )}
      <div
        className={classNames(
          'flex items-center justify-between',
          !skip && 'hidden'
        )}
      >
        <p className='2xl:text-32 text-2xl  text-primary font-cabin'>{title}</p>
        <p
          className={classNames(
            '2xl:text-lg text-base text-primary underline text-right cursor-pointer',
            !skip && 'hidden'
          )}
          onClick={handleUndoSkip}
        >
          {t('UNDO SKIP')}
        </p>
      </div>
      <div className={skip ? 'hidden' : ''}>
        <div className='flex items-center justify-between mt-5'>
          <p className='2xl:text-32 text-2xl  text-primary font-cabin'>
            {title}
          </p>
          <p
            className='2xl:text-lg text-base text-primary underline cursor-pointer'
            onClick={handleSkip}
          >
            {t('SKIP')}
          </p>
        </div>
        {children}
      </div>
    </div>
  );
};

const JobQuestions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const { details, loading, questions } = useSelector(
    (state: RootState) => state.job
  );
  const { selected } = useSelector((state: RootState) => state.companies);
  const { user } = useSelector((state: RootState) => state.auth);

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (
      nextLocation.pathname !== '/create-video' &&
      nextLocation.pathname !== '/create-job/questions' &&
      nextLocation.pathname !== '/create-job'
    ) {
      return true;
    } else {
      return false;
    }
  });

  const formik = useFormik<IJobQuestion>({
    initialValues: {
      isUSAuthorized: false,
      isSponsorWorkVisa: false,
      is_referral_name: false,
      is_referral_email: false,
      isDisability: false,
      isVeteran: false,
      isRacialBackground: false,
      isFormerEmployee: false,
      isAffiliateCompany: false,
      isReferred: false,
    },
    onSubmit: values => {
      if (selected) {
        dispatch.job.setQuestions(values);
        dispatch.job.handleCreateJob({
          values: {
            ...values,
            ...details,
            company_id: selected.company_id || selected.id,
          } as ICreateJob,
          navigate,
        });
      }
    },
  });

  const handleCancel = () => {
    if (blocker.proceed) {
      blocker.proceed();
    }
  };

  const handleContinue = () => {
    const draftList: IDraft[] = JSON.parse(
      localStorage.getItem(CONSTANTS.DRAFTS) || '[]'
    );
    // @ts-ignore
    const data: ICreateJobDraft = {};
    const draft: any = {};

    data.company = selected!;
    data.company_id = selected!.company_id;
    data.job_details = details!;
    data.step = 'questions';
    data.type = 'create-job';

    draft.id = `${user!.id}${user!.role}${selected?.company_id}${
      details?.title
    }`;
    draft.data = data;
    draft.role = user!.role as ROLES;
    draft.type = 'create-job';
    draft.user_id = user!.id;
    if (draftList.filter((d: IDraft) => d.id === draft.id).length === 0) {
      draftList.push(draft);
      localStorage.setItem(CONSTANTS.DRAFTS, JSON.stringify(draftList));
    } else {
      const index = draftList.findIndex((d: IDraft) => d.id === draft.id);
      draftList[index] = draft;
      localStorage.setItem(CONSTANTS.DRAFTS, JSON.stringify(draftList));
    }
    handleCancel();
  };

  useEffect(() => {
    if (questions) {
      formik.setValues(questions);
    }
  }, []);

  if (!details) {
    return <Navigate to='/explore' replace />;
  }

  return (
    <div className='flex-grow'>
      <div className='w-full max-w-[821px] mx-auto flex flex-col gap-[9px]'>
        <Card
          title={t('Work Authorization')}
          desc={t('Applicants must answer to the questions you select')}
          onSkip={() => {
            formik.setFieldValue('isUSAuthorized', false);
            formik.setFieldValue('isSponsorWorkVisa', false);
          }}
          onUndoSkip={() => () => {
            formik.setFieldValue('isUSAuthorized', false);
            formik.setFieldValue('isSponsorWorkVisa', false);
          }}
        >
          <div className='flex flex-col 2xl:gap-4 gap-3 mt-5'>
            <div className='flex items-center gap-2'>
              <Checkbox
                value={!!formik.values.isUSAuthorized}
                onChange={(val: boolean) =>
                  formik.setFieldValue('isUSAuthorized', val)
                }
              />
              <p className='text-base font-medium'>
                {t('Are you authorized to work in the United States?')}
              </p>
            </div>
            <div className='flex items-center gap-2'>
              <Checkbox
                value={!!formik.values.isSponsorWorkVisa}
                onChange={(val: boolean) =>
                  formik.setFieldValue('isSponsorWorkVisa', val)
                }
              />
              <p className='text-base font-medium'>
                {t(
                  'Do you now or in the future require a working visa sponsorship?'
                )}
              </p>
            </div>
          </div>
        </Card>
        <Card
          title={t('Diversity & Inclusion')}
          onSkip={() => {
            formik.setFieldValue('isDisability', false);
            formik.setFieldValue('isVeteran', false);
            formik.setFieldValue('isRacialBackground', false);
          }}
          onUndoSkip={() => {
            formik.setFieldValue('isDisability', false);
            formik.setFieldValue('isVeteran', false);
            formik.setFieldValue('isRacialBackground', false);
          }}
        >
          <div className='flex flex-col 2xl:gap-4 gap-3 mt-5'>
            <div className='flex items-center gap-2'>
              <Checkbox
                value={!!formik.values.isDisability}
                onChange={(val: boolean) =>
                  formik.setFieldValue('isDisability', val)
                }
              />
              <p className='text-base font-medium'>
                {t('Do you have any disability?')}
              </p>
            </div>
            <div className='flex items-center gap-2'>
              <Checkbox
                value={!!formik.values.isVeteran}
                onChange={(val: boolean) =>
                  formik.setFieldValue('isVeteran', val)
                }
              />
              <p className='text-base font-medium'>
                {t('Do you identify as a veteran?')}
              </p>
            </div>
            <div className='flex items-center gap-2'>
              <Checkbox
                value={!!formik.values.isRacialBackground}
                onChange={(val: boolean) =>
                  formik.setFieldValue('isRacialBackground', val)
                }
              />
              <p className='text-base font-medium'>
                {t('What is your racial background?')}
              </p>
            </div>
          </div>
        </Card>
        <Card
          title={t('Employment History')}
          onSkip={() => {
            formik.setFieldValue('isFormerEmployee', false);
            formik.setFieldValue('isAffiliateCompany', false);
          }}
          onUndoSkip={() => {
            formik.setFieldValue('isFormerEmployee', false);
            formik.setFieldValue('isAffiliateCompany', false);
          }}
        >
          <div className='flex flex-col 2xl:gap-4 gap-3 mt-5'>
            <div className='flex items-center gap-2'>
              <Checkbox
                value={!!formik.values.isFormerEmployee}
                onChange={(val: boolean) =>
                  formik.setFieldValue('isFormerEmployee', val)
                }
              />
              <p className='text-base font-medium'>
                {t('Have you ever worked for')} {selected?.name}
              </p>
            </div>
            <div className='flex items-center gap-2'>
              <Checkbox
                value={!!formik.values.isAffiliateCompany}
                onChange={(val: boolean) =>
                  formik.setFieldValue('isAffiliateCompany', val)
                }
              />
              <p className='text-base font-medium'>
                {t('Have you ever worked for any affiliate of')}{' '}
                {selected?.name}
              </p>
            </div>
          </div>
        </Card>

        <Card
          title={t('Employment Referral')}
          onSkip={() => {
            formik.setFieldValue('isReferred', false);
            formik.setFieldValue('is_referral_name', false);
            formik.setFieldValue('is_referral_email', false);
          }}
          onUndoSkip={() => {
            formik.setFieldValue('isReferred', false);
            formik.setFieldValue('is_referral_name', false);
            formik.setFieldValue('is_referral_email', false);
          }}
        >
          <div className='flex flex-col 2xl:gap-4 gap-3 mt-5'>
            <div className='flex items-center gap-2'>
              <Checkbox
                value={!!formik.values.isReferred}
                onChange={(val: boolean) =>
                  formik.setFieldValue('isReferred', val)
                }
              />
              <p className='text-base font-medium'>
                {t('Were you referred by someone from')} {selected?.name}
              </p>
            </div>
            <div className='flex items-center gap-2'>
              <Checkbox
                value={!!formik.values.is_referral_name}
                onChange={(val: boolean) =>
                  formik.setFieldValue('is_referral_name', val)
                }
              />
              <p className='text-base font-medium'>
                {t('Please enter name of your referrer')}
              </p>
            </div>
            <div className='flex items-center gap-2'>
              <Checkbox
                value={!!formik.values.is_referral_email}
                onChange={(val: boolean) =>
                  formik.setFieldValue('is_referral_email', val)
                }
              />
              <p className='text-base font-medium'>
                {t('Please enter email of your referrer')}
              </p>
            </div>
          </div>
        </Card>

        <Button
          label={t('Next')}
          className='2xl:!text-22 !text-lg mt-[37px]'
          onClick={() => formik.handleSubmit()}
          loading={loading}
        />
        <div className='h-10'></div>
      </div>
      {blocker.state === 'blocked' && (
        <Draft
          onCancel={handleCancel}
          onContinue={handleContinue}
          message='Your progress may be lost. Want to save in Drafts?'
        />
      )}
    </div>
  );
};

export default JobQuestions;
