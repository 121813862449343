import React from 'react';
import Accordion from './Accordion';

import { useSelector } from 'react-redux';
import { RootState } from 'store';

const FAQs = () => {
  const { faqs } = useSelector((state: RootState) => state.faqs);

  return (
    <div className='2xl:px-6 px-4 2xl:py-9 py-5'>
      <p className='2xl:text-2xl text-xl font-bold'>FAQs</p>
      <div className='flex flex-col 2xl:gap-5 gap-4  2xl:mt-[55px] mt-6'>
        {faqs.map(f => (
          <Accordion question={f.question} answer={f.answer} key={f.id} />
        ))}
      </div>
    </div>
  );
};

export default FAQs;
