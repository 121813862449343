import React, { useState } from 'react';

import Button from 'components/Button/Button';
import SaveProgress from 'Dialogs/SaveProgress/SaveProgress';
import SubmitVideo from 'Dialogs/SubmitVideo/SubmitVideo';
import ASSETS from 'assets';

const MergeVideos = () => {
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  return (
    <div className='flex-grow  w-full max-w-[620px] pb-[100px] mx-auto'>
      <div
        className='h-full relative bg-yellow-500 flex bg-no-repeat bg-cover flex-col justify-between px-[28px] pb-[36px] pt-[28px]'
        style={{ backgroundImage: `url(${ASSETS.video})` }}
      >
        <div>
          <div className='flex items-center justify-between'>
            <div
              className='w-[54px] h-[54px] rounded-full bg-[#0000001a] grid place-items-center cursor-pointer'
              onClick={() => setOpenSaveModal(true)}
            >
              <img src={ASSETS.cross} alt='' />
            </div>
            <div className='w-[54px] h-[54px] rounded-full bg-[#0000001a] grid place-items-center cursor-pointer'>
              <img src={ASSETS.retake} alt='' />
            </div>
          </div>
          <img src={ASSETS.merge} className='w-full mt-[10px]' alt='' />
        </div>
        <Button
          label={'Submit Application'}
          className='w-full !text-22'
          onClick={() => setOpenSubmitModal(true)}
        />
      </div>
      {openSaveModal && (
        <SaveProgress open={openSaveModal} setOpen={setOpenSaveModal} />
      )}
      {openSubmitModal && (
        <SubmitVideo open={openSubmitModal} setOpen={setOpenSubmitModal} />
      )}
    </div>
  );
};

export default MergeVideos;
