import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';

import { AgoraContext } from 'context/AgoraContext';

import Chats from './Components/Chats';
import Messages from './Components/Messages';
import { t } from 'i18next';
import ASSETS from 'assets';
import { IChat } from 'types/interfaces';
import { getChats } from 'http/userService';
import { useSocket } from 'context/SocketContex';

const Chat = () => {
  const dispatch = useDispatch<Dispatch>();

  const socket = useSocket();

  const conn = useContext(AgoraContext);

  const { chats, sChats } = useSelector((state: RootState) => state.chat);
  const { user } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    dispatch.chat.setCount(0);
    if (user) {
      dispatch.chat.handleFetchChats({ user, conn });
    }
    dispatch.chat.handleFetchSChats();
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (socket && socket.connected) {
      socket.on('message', () => {
        dispatch.chat.handleFetchSChats();
      });
    }
  }, [socket]);

  return (
    <>
      {chats.length + sChats.length > 0 ? (
        <div className='w-full flex-grow flex flex-col'>
          <div className='w-full flex-grow flex items-stretch border-[0.5px] border-grey-600 rounded-lg overflow-hidden'>
            <div className='2xl:min-w-[350px] min-w-[340px] 2xl:w-[380px] w-[350px] h-full bg-white border-r-[0.5px] border-r-grey-600 flex flex-col'>
              <div className='px-[31px] flex items-center 2xl:h-[79.5px] h-[64.5px]'>
                <p className='2xl:text-[28px] text-xl text-primary font-medium'>
                  {t('Inbox')}
                </p>
              </div>
              <Chats />
            </div>
            <Messages />
          </div>
        </div>
      ) : (
        <div className=' flex-grow flex flex-col items-center justify-center'>
          <img src={ASSETS.noMessage} className='2xl:w-auto w-[240px]' alt='' />
          <p className='text-2xl text-primary 2xl:text-32 font-medium'>
            No Messages Yet
          </p>
          <p className='2xl:text-2xl text-lg text-placeholder mt-2'>
            Your messages will appear here
          </p>
        </div>
      )}
    </>
  );
};

export default Chat;
