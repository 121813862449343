import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';

import { googleApiKey } from 'app.config';
import { useJsApiLoader } from '@react-google-maps/api';
import classNames from 'classnames';
import ASSETS from 'assets';

interface IProps {
  selected?: string[];
  lable?: string;
  placeholder?: string;
  onUpdate?: (val: string) => void;
  showSelection?: boolean;
  containerStyle?: string;
  inputStyles?: string;
  menuStyles?: string;
  onOptionChange?: React.Dispatch<React.SetStateAction<string[]>>;
}

const LocationAutoComplete: FC<IProps> = ({
  lable,
  placeholder,
  onUpdate,
  showSelection = false,
  selected: selectedValues,
  containerStyle,
  inputStyles,
  menuStyles,
  onOptionChange,
}) => {
  const [open, setOpen] = useState(false);

  const [search, setSearch] = useState('');

  const [predictions, setPredictions] = useState<string[]>([]);
  const [selected, setSelected] = useState<string[]>([]);

  const toggleMenu = () => setOpen(!open);

  const hadleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearch(value);

    // Clear predictions if input is empty
    if (!value) {
      setPredictions([]);
      if (onOptionChange) {
        onOptionChange([]);
      }
      return;
    }

    if (autocompleteService.current && value) {
      autocompleteService.current.getPlacePredictions(
        {
          input: value,
          componentRestrictions: { country: 'us' },
        },
        async (predictions, status) => {
          if (
            status === window.google.maps.places.PlacesServiceStatus.OK &&
            predictions
          ) {
            let list: string[] = [];
            list = predictions.map(prediction => prediction.description);
            setPredictions(list);
            if (onOptionChange) {
              onOptionChange(list);
            }
          } else {
            setPredictions([]);
            if (onOptionChange) {
              onOptionChange([]);
            }
          }
        }
      );
    }
  };

  const autocompleteService =
    useRef<google.maps.places.AutocompleteService | null>(null);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: googleApiKey || '',
    libraries: ['places'],
  });

  const handleSelect = (val: string) => {
    if (selected?.includes(val)) {
      setSelected(selected.filter(s => s !== val));
      if (onUpdate) {
        onUpdate(val);
      }
    } else {
      setSelected(prev => [...prev, val]);
      if (onUpdate) {
        onUpdate(val);
      }
    }
    setPredictions([]);
    if (onOptionChange) {
      onOptionChange([]);
    }
    setSearch('');
  };

  useEffect(() => {
    if (isLoaded && !autocompleteService.current) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
  }, [isLoaded]);

  useEffect(() => {
    if (selectedValues?.length) {
      setSelected(selectedValues);
    }
  }, [selectedValues]);

  return (
    <div className='w-full max-w-[800px]'>
      {lable && <p className='text-base font-medium mb-1'>{lable}</p>}
      <div className='relative'>
        <div
          className={classNames(
            'flex items-center gap-2 bg-white border border-grey-400 rounded min-h-11 px-[15px] flex-wrap py-1 relative',
            // inputClassName,
            // error && 'border-red'
            containerStyle || ''
          )}
          onClick={toggleMenu}
        >
          <>
            {showSelection &&
              selected?.map(i => (
                <div
                  key={i}
                  className='bg-secondary px-[7px] h-7 flex items-center rounded'
                >
                  <p className='text-[12px] font-medium'>{i}</p>
                  <img
                    src={ASSETS.cross}
                    className='w-3 h-3 cursor-pointer'
                    onClick={() => handleSelect(i)}
                    alt=''
                  />
                </div>
              ))}
          </>
          <input
            value={search}
            onChange={hadleChange}
            type='text'
            className={classNames(
              'flex-grow h-full outline-none text-base font-medium placeholder:opacity-85 placeholder:text-black',
              inputStyles
            )}
            placeholder={placeholder || ''}
          />
          {predictions.length > 0 && (
            <ul className='absolute w-full bg-white border border-gray-300 mt-1 rounded-md z-10 divide-y bottom-0 translate-y-[calc(100%_+_10px)] left-0'>
              {predictions.map((prediction, index) => (
                <li
                  key={prediction}
                  className={classNames(
                    'cursor-pointer p-2 hover:bg-gray-100 flex items-center justify-between',
                    menuStyles
                  )}
                  onClick={() => handleSelect(prediction)}
                >
                  {prediction}

                  {selected.includes(prediction) && (
                    <img src={ASSETS.check} alt='' />
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default LocationAutoComplete;
