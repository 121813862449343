import React, { useEffect, useState } from 'react';
import Button from 'components/Button/Button';
import ASSETS from 'assets';
import { getLanguage } from 'utils';
import i18next, { t } from 'i18next';

interface ILang {
  label: string;
  key: string;
}

const Languages: ILang[] = [
  {
    label: 'English',
    key: 'en',
  },
  {
    label: 'Arabic',
    key: 'ar',
  },
  {
    key: 'zh',
    label: 'Chinese',
  },
  {
    label: 'French',
    key: 'fr',
  },
  {
    label: 'Italian',
    key: 'it',
  },
  {
    label: 'Japanese',
    key: 'ja',
  },
  {
    label: 'Korean',
    key: 'ko',
  },
  {
    label: 'Portuguese',
    key: 'pt',
  },
  {
    label: 'Russian',
    key: 'ru',
  },
  {
    label: 'Spanish',
    key: 'es',
  },{
    label:"Hindi",
    key:"hi"
  }
];

const Language = () => {
  const [lang, setLang] = useState({
    label: 'English',
    key: 'en',
  });
  const handleLanguage = (lang: ILang) => {
    setLang(lang);
  };

  const handleConfirm = () => {
    i18next.changeLanguage(lang.key);
  };

  useEffect(() => {
    const language = getLanguage();
    const l = Languages.find(lag => lag.key === language);
    if (l) setLang(l);
  }, []);

  return (
    <div>
      {Languages.map(lag => (
        <div
          key={lag.key} // Corrected here
          className='flex justify-between items-center 2xl:h-[56px] h-10 2xl:px-11 px-6 border-b-[0.5px] border-b-grey-600 cursor-pointer'
          onClick={() => handleLanguage(lag)}
        >
          <p className='2xl:text-lg text-base font-medium'>{lag.label}</p>
          {lag.key === lang.key && (
            <img src={ASSETS.check} className='2xl:w-[25px] w-5' alt='' />
          )}
        </div>
      ))}
      <div className='flex justify-between items-center 2xl:px-11 px-6 2xl:py-6 py-[22px]'>
        <Button
          label={t('Confirm Language')}
          className='w-full 2xl:!text-22 !text-lg'
          onClick={handleConfirm}
        />
      </div>
    </div>
  );
};

export default Language;
