import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';

import { googleApiKey, processCities } from 'app.config';

import styled from 'styled-components';
import ASSETS from 'assets';
import ClickAwayListener from 'react-click-away-listener';
import { t } from 'i18next';
import { useJsApiLoader } from '@react-google-maps/api';

const options = processCities();

interface IProps {
  location: string;
  setLocation: React.Dispatch<React.SetStateAction<string>>;
}

const LocationSearch: FC<IProps> = ({ location, setLocation }) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');

  const [predictions, setPredictions] = useState<string[]>([]);

  const autocompleteService =
    useRef<google.maps.places.AutocompleteService | null>(null);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: googleApiKey || '',
    libraries: ['places'],
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearch(value);

    // Clear predictions if input is empty
    if (!value) {
      setPredictions([]);
      return;
    }

    if (autocompleteService.current && value) {
      autocompleteService.current.getPlacePredictions(
        {
          input: value,
          componentRestrictions: { country: 'us' },
        },
        async (predictions, status) => {
          if (
            status === window.google.maps.places.PlacesServiceStatus.OK &&
            predictions
          ) {
            let list: string[] = [];
            list = predictions.map(prediction => prediction.description);
            setPredictions(list);
          } else {
            setPredictions([]);
          }
        }
      );
    }
  };

  const handleClose = () => {
    if (search !== '' && location) {
      setSearch(location);
      setOpen(false);
    } else {
      setSearch('');
      setLocation('');
      setOpen(false);
    }
  };

  const handleSelect = (val: string) => {
    setSearch(val);
    setLocation(val);
    setOpen(false);
  };

  useEffect(() => {
    if (isLoaded && !autocompleteService.current) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
  }, [isLoaded]);

  return (
    <Container className='relative'>
      <div
        className='input border border-grey-600 w-full 2xl:h-10 h-9 2xl:pl-3 2xl:pr-2  pl-2 pr-1 rounded-3xl overflow-hidden items-center flex gap-1'
        onClick={() => setOpen(true)}
      >
        <img src={ASSETS.location} className='w-5 2xl:w-auto' alt='' />
        <input
          type='search'
          placeholder={t('Search City or State')}
          className='flex-grow mr-5 h-full placeholder:text-placeholderColor 2xl:text-base text-sm outline-none border-none bg-transparent italic'
          onChange={handleChange}
          value={t(search)}
        />
        <div className='absolute right-0 h-full aspect-square grid place-items-center rounded-3xl pointer-events-none'>
          {/* <img src={ASSETS.chevronDown} alt='' /> */}
        </div>
      </div>
      {search && open && (
        <ClickAwayListener onClickAway={handleClose}>
          <div className='absolute bottom-0 w-full  bg-white shadow-popup translate-y-[calc(100%_+_5px)] rounded-lg max-h-[400px] overflow-auto divide-y'>
            {predictions.map(op => {
              if (op.toLowerCase().includes(search.toLowerCase())) {
                return (
                  <div
                    className='px-3 py-2 cursor-pointer'
                    key={op}
                    onClick={() => handleSelect(op)}
                  >
                    <p className='text-base '>{t(op)}</p>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </ClickAwayListener>
      )}
    </Container>
  );
};

export default LocationSearch;

const Container = styled.div`
  flex-grow: 1;
  max-width: 373px;
`;
