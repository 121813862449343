import React, { useEffect, useState } from 'react';

import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import AutocompleteSelect from 'components/AutocompleteSelect/AutocompleteSelect';
import Button from 'components/Button/Button';
import { t } from 'i18next';
import { getPreferences, searchPreferences } from 'http/authService';
import LocationAutoComplete from 'components/LocationAutoComplete/LocationAutoComplete';

const industryOptions = [
  'Architecture and Construction',
  'Arts, Design, Entertainment, Sports, and Media',
  'Building and Grounds Cleaning and Maintenance',
  'Business and Finance',
  'Business Management and Administration',
  'Community and Social Services',
  'Construction and Extraction',
  'Education, Training, and Library',
  'Farming, Fishing and Forestry',
  'Food Preparation and Serving Related',
  'Government and Public Administration',
  'Healthcare Practitioners and Technical',
  'Healthcare Support',
  'Installation, Maintenance and Repair',
  'Legal',
  'Life, Physical and Social Science',
  'Marketing, Sales, and Service',
  'Military',
  'Office and Administrative Support',
  'Personal Care and Services',
  'Protective and Security Service',
  'Science, Technology, Engineering, and Mathematics',
  'Transportation. Distribution and Logistics',
];

const SearchPreferences = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      location: [],
      jobRole: [],
      jobLevel: [],
      jobType: [],
      industry: [],
      experience: [],
    } as { [x: string]: any },
    onSubmit: values => {
      console.log(values);
    },
  });

  const handleChange = (val: string, name: string) => {
    const values = formik.values[name];
    if (values.includes(val)) {
      formik.setFieldValue(
        name,
        values.filter((v: string) => v !== val)
      );
    } else {
      formik.setFieldValue(name, [...values, val]);
    }
  };

  const handleClear = async () => {
    try {
      setLoading(true);
      formik.setValues({
        location: [],
        jobRole: [],
        jobLevel: [],
        jobType: [],
        industry: [],
        experience: [],
      });
      await searchPreferences({
        location: null,
        job_role: null,
        job_level: null,
        job_type: null,
        experience: null,
        industry: null,
        role: user?.role || 'A',
      });
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGetSearchPreferences = async () => {
    try {
      setLoading(true);
      const { data } = await getPreferences();
      data.data.forEach((e: any) => {
        if (e.role === user?.role) {
          formik.setValues({
            location: e.location || [],
            jobRole: e.job_role || [],
            jobLevel: e.job_level || [],
            jobType: e.job_type || [],
            industry: e.industry || [],
            experience: e.experience || [],
          });
        }
      });
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleApplyChanges = async () => {
    try {
      setLoading(true);
      await searchPreferences({
        location: formik.values.location,
        job_role: formik.values.jobRole,
        job_level: formik.values.jobLevel,
        job_type: formik.values.jobType,
        experience: formik.values.experience,
        industry: formik.values.industry,
        role: user?.role || 'A',
      });
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleLocationChange = (val: string) => {
    let list = [...formik.values.location];

    if (list.includes(val)) {
      list = list.filter((v: string) => v !== val);
    } else {
      list.push(val);
    }
    formik.setFieldValue('location', list);
  };

  useEffect(() => {
    handleGetSearchPreferences();
    // eslint-disable-next-line
  }, []);

  return (
    <div className='flex-grow'>
      <div className='w-full max-w-[821px] bg-white 2xl:p-7 p-5 border-[0.5px] border-grey-600'>
        <div className='flex items-center justify-between'>
          <p className='2xl:text-32 text-2xl text-primary font-medium'>
            {t('Search Preferences')}
          </p>
          <p className='2xl:text-sm text-xs font-medium'>
            {t('Filter Search')} (
            {formik.values.location?.length +
              formik.values.jobRole?.length +
              formik.values.jobLevel?.length +
              formik.values.jobType?.length}
            )
          </p>
        </div>
        <div className='flex flex-col 2xl:gap-6 gap-4 2xl:mt-[55px] mt-5'>
          <LocationAutoComplete
            selected={formik.values.location}
            lable='Location'
            placeholder={t('Search')}
            onUpdate={val => handleLocationChange(val)}
            showSelection
          />
          {/* <AutocompleteSelect
            selected={formik.values.location}
            options={Object.keys(stateAbbreviations)}
            placeholder={t('Search')}
            lable={t('Location')}
            onSelect={val => handleChange(val, 'location')}
            onDeselect={val => handleChange(val, 'location')}
            showDownArrow={false}
            searchIcon={false}
          /> */}

          <AutocompleteSelect
            options={industryOptions}
            placeholder={t('Select')}
            lable={t('Occupation')}
            searchIcon={false}
            selected={formik.values.jobRole}
            onSelect={val => handleChange(val, 'jobRole')}
            onDeselect={val => handleChange(val, 'jobRole')}
            showMenu
            disableSearch
          />
          <AutocompleteSelect
            options={[
              'Internship',
              'Entry level',
              'Associate',
              'Mid/Senior level',
              'Director',
              'Executive',
            ]}
            placeholder={t('Select')}
            lable={t('Job Level')}
            searchIcon={false}
            selected={formik.values.jobLevel}
            onSelect={val => handleChange(val, 'jobLevel')}
            onDeselect={val => handleChange(val, 'jobLevel')}
            showMenu
            disableSearch
          />

          <AutocompleteSelect
            options={['Remote', 'Onsite', 'Hybrid']}
            placeholder={t('Select')}
            lable={t('Job Type')}
            searchIcon={false}
            selected={formik.values.jobType}
            onSelect={val => handleChange(val, 'jobType')}
            onDeselect={val => handleChange(val, 'jobType')}
            showMenu
            disableSearch
          />
        </div>
        <div className='flex flex-col items-center 2xl:mt-[81px] mt-7'>
          <Button
            label={t('Apply')}
            className='w-full max-w-[369px] 2xl:!text-22 !text-lg'
            onClick={handleApplyChanges}
            loading={loading}
          />
          <p
            className='2xl:text-2xl text-lg font-medium mt-4 cursor-pointer'
            onClick={handleClear}
          >
            {t('Clear')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SearchPreferences;
