import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { IFAQ, IHelpCategory, IHelpHotline } from 'types/interfaces';
import {
  getAllHelpCategories,
  getFaqs,
  getHelpCenterHotlines,
} from 'http/settingsService';

interface IState {
  faqs: IFAQ[];
  loading: boolean;
  helpCenter: IHelpCategory[];
  hotlines: IHelpHotline[];
}

export const faqs = createModel<RootModel>()({
  name: 'faqs',
  state: {
    loading: false,
    faqs: [],
    helpCenter: [],
    hotlines: [],
  } as IState,
  reducers: {
    setFaqs(state, payload: IFAQ[]) {
      state.faqs = payload;
    },
    setHelpCenter(state, payload: IHelpCategory[]) {
      state.helpCenter = payload;
    },
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setHotlines(state, payload: IHelpHotline[]) {
      state.hotlines = payload;
    },
  },
  effects: dispatch => ({
    async handleGetFaqs() {
      try {
        dispatch.faqs.setLoading(true);
        const { data } = await getFaqs();
        dispatch.faqs.setFaqs(data.data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.faqs.setLoading(false);
      }
    },
    async handleGetHelpCenter() {
      try {
        dispatch.faqs.setLoading(true);
        const { data } = await getAllHelpCategories();
        dispatch.faqs.setHelpCenter(data.data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.faqs.setLoading(false);
      }
    },
    async handleGetHotlines() {
      try {
        dispatch.faqs.setLoading(true);
        const { data } = await getHelpCenterHotlines();
        dispatch.faqs.setHotlines(data.data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.faqs.setLoading(false);
      }
    },
  }),
});
