import React, { FC } from 'react';

import { t } from 'i18next';

import Button from 'components/Button/Button';
import DialogLayout from 'Dialogs/DialogLayout';
// import ASSETS from 'assets';

interface IProps {
  message: string;
  onCancel: () => void;
  onContinue: () => void;
}

const Draft: FC<IProps> = ({ message, onCancel, onContinue }) => {
  const handleClick = () => {
    onContinue();
  };

  const handleClose = () => {
    onCancel();
  };

  return (
    <DialogLayout open={true}>
      <div className='w-[100vw] 2xl:max-w-[484px] max-w-[440px] rounded-[16px] bg-grey-100 p-6 2xl:pb-12 pb-6'>
        {/* <div className='close flex justify-end'>
          <img
            src={ASSETS.cross}
            className='cursor-pointer'
            role='button'
            onClick={handleClose}
            alt=''
          />
        </div> */}
        <p className='2xl:text-32 text-2xl text-primary font-medium text-center 2xl:mt-7 mt-3'>
          Save to Drafts
        </p>
        <p className='2xl:text-lg text-base text-center w-[35ch] mx-auto mt-[18px]'>
          {message}
        </p>
        <div className='flex flex-col 2xl:mt-11 mt-7 gap-[11px]'>
          <Button
            label={t('Continue')}
            className='w-full 2xl:!text-22 !text-lg'
            onClick={handleClick}
          />
          <p
            className='2xl:text-xl text-lg font-medium text-center inline-block mx-auto cursor-pointer'
            onClick={handleClose}
          >
            {t('No Thanks')}
          </p>
        </div>
      </div>
    </DialogLayout>
  );
};

export default Draft;
