import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { RootState } from 'store';

import ASSETS from 'assets';
import { useNavigate } from 'react-router-dom';

const ApplcantProfile = () => {
  const navigate = useNavigate();
  const handleEditProfile = () => navigate('/settings/edit-profile');

  const { user } = useSelector((state: RootState) => state.auth);

  return (
    <div className='bg-primary pt-6 pb-4 px-4 flex flex-col items-center'>
      <div className='image-picker 2xl:size-[162px] size-[130px]'>
        <img
          src={user?.image_url || ASSETS.preview}
          className='2xl:size-[162px] size-[130px] rounded-full object-cover'
          alt=''
        />
      </div>
      <div className='flex items-center gap-1 mt-2'>
        <p className='2xl:text-2xl text-xl text-white font-bold leading-8'>
          {user?.first_name || ''} {user?.last_name || ''}
        </p>
        <img
          src={ASSETS.editCircle}
          className='cursor-pointer w-5 2xl:w-auto'
          onClick={handleEditProfile}
          alt=''
        />
      </div>
      <p className='2xl:text-lg text-base text-white font-bold leading-6'>
        {user?.job_title}
      </p>
      <p className='2xl:text-base base-sm text-white font-bold'>
        {user?.location}
      </p>
    </div>
  );
};

export default ApplcantProfile;
