import React, { createContext, useContext } from 'react';

import { Editor, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import Underline from '@tiptap/extension-underline';
import TextStyle from '@tiptap/extension-text-style';
import Text from '@tiptap/extension-text';
import Color from '@tiptap/extension-color';
import TextAlign from '@tiptap/extension-text-align';
import Highlight from '@tiptap/extension-highlight';
import BulletList from '@tiptap/extension-bullet-list';
import ListItem from '@tiptap/extension-list-item';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import OrderedList from '@tiptap/extension-ordered-list';
import Link from '@tiptap/extension-link';
import CodeBlock from '@tiptap/extension-code-block';

type EditorContextType = Editor | null;

export const EditorContext = createContext<EditorContextType>(null);

export const useTipTap = () => useContext(EditorContext);

const EditorProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const editor = useEditor({
    extensions: [
      StarterKit.configure(),
      Placeholder.configure({
        placeholder: '',
      }),
      Paragraph,
      Document,
      Underline,
      Text,
      TextStyle,
      Color,
      TextAlign.configure({
        alignments: ['left', 'center', 'right', 'justify'],
        types: ['heading', 'paragraph'],
        defaultAlignment: 'left',
      }),
      Highlight.configure({
        multicolor: true,
      }),
      BulletList.configure({
        HTMLAttributes: {
          class: 'my-custom-list',
        },
      }),
      OrderedList,
      ListItem,
      Link.configure({
        openOnClick: true,
        HTMLAttributes: {
          rel: 'noopener noreferrer',
          target: '_blank',
          class: 'editor-link',
        },
        protocols: [
          {
            scheme: 'tel',
            optionalSlashes: true,
          },
        ],
      }),
      CodeBlock.configure({
        HTMLAttributes: {
          class: 'code-block',
        },
      }),
    ],
    content: '',
  });
  return (
    <EditorContext.Provider value={editor}>{children}</EditorContext.Provider>
  );
};

export default EditorProvider;
