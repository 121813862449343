import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { IVideoQuestion } from 'types/interfaces';
import { introQuestions } from 'app.config';

interface IState {
  data: IVideoQuestion[];
  selected: IVideoQuestion | null;
  finalVideo: File | null;
  thumbnail: File | null;
}

export const video = createModel<RootModel>()({
  name: 'video',
  state: {
    data: introQuestions,
    selected: null,
    finalVideo: null,
    thumbnail: null,
  } as IState,
  reducers: {
    setQuestion(state, payload: { id: number; value: string }) {
      const data = state.data;
      const item = data.find(item => item.id === payload.id)!;
      const index = data.indexOf(item);
      item.question = payload.value;
      data[index] = item;
      state.data = data;
    },
    setDuration(state, payload: { id: number; value: string | number }) {
      const data = state.data;
      const item = data.find(item => item.id === payload.id)!;
      const index = data.indexOf(item);
      item.duration = payload.value;
      data[index] = item;
      state.data = data;
    },
    setData(state, payload: IVideoQuestion[]) {
      state.data = payload;
    },
    // payload ---> id
    removeQuestion(state, payload: number) {
      const data = state.data;
      const newData = data.filter(item => item.id !== payload);
      state.data = newData;
    },
    addNewQuestion(state) {
      state.data.push({
        question: '',
        duration: '',
        id: new Date().getTime(),
        media: null,
        orientations: '',
      });
    },
    setSelected(state, payload: IVideoQuestion | null) {
      state.selected = payload;
    },
    setMedia(state, payload: { id: number; value: File | null }) {
      const data = state.data;
      const item = data.find(item => item.id === payload.id)!;
      const index = data.indexOf(item);
      item.media = payload.value;
      data[index] = item;
      state.data = data;
    },
    setOrientation(
      state,
      payload: { id: number; value: 'landscape' | 'portrait' }
    ) {
      const data = state.data;
      const item = data.find(item => item.id === payload.id)!;
      const index = data.indexOf(item);
      item.orientations = payload.value;
      data[index] = item;
      state.data = data;
    },
    setFinalVideo(state, payload: File | null) {
      state.finalVideo = payload;
    },
    setThumbnail(state, payload: File | null) {
      state.thumbnail = payload;
    },
  },
});
